import { useTranslations } from "next-intl";
import React from "react";
import { toast } from "react-toastify";
import { isGlobalLoadingVar, isLoginModalOpenedVar } from "../../graphql/cache";
import { useLogGuestUserIn } from "../../hooks/useLogGuestUserIn";
import {
  LoginWithGoogleInput,
  useIsLoginModalOpenedQuery,
} from "../../resolvers-types";
import Modal from "../modal/Modal";
import LoginOptions from "./LoginOptions";

export default function LoginModal() {
  const translate = useTranslations();
  const { data } = useIsLoginModalOpenedQuery();
  const isModalOpened = data?.isLoginModalOpened;
  const loginUser = useLogGuestUserIn();

  const closeModal = () => {
    isLoginModalOpenedVar(false);
  };

  const logUserIn = async (loginUserInput: LoginWithGoogleInput) => {
    try {
      await loginUser(loginUserInput);
      closeModal();
      isGlobalLoadingVar(false);
      toast.success(translate("loginSuccess"));
    } catch (error) {
      toast.error(error.message);
      isGlobalLoadingVar(false);
    }
  };

  return (
    <Modal
      title={translate("login") as string}
      onClose={closeModal}
      isVisible={isModalOpened}
      shouldHideFooter
    >
      <div className="w-full">
        <div className="w-full">
          <LoginOptions handleLogUserIn={logUserIn} />
        </div>
      </div>
    </Modal>
  );
}
