import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "../../providers/ThemeProvider";
import { ThemeType } from "../../types/common";

export default function AppToastContainer() {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === ThemeType.Dark;

  return (
    <ToastContainer
      position="bottom-center"
      theme={isDarkMode ? "dark" : "light"}
    />
  );
}
