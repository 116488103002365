import React from "react";

interface LoginButtonProps {
  title: string;
  svgIconUrl: string;
  onClick: () => void;
  className?: string;
  logoClassName?: string;
}

export default function LoginButton({
  title,
  svgIconUrl,
  onClick,
  className,
  logoClassName,
}: LoginButtonProps) {
  return (
    <div
      className={`w-80 h-14 hover:bg-gray-100 cursor-pointer rounded-lg flex flex-row items-center justify-center px-3 border border-dark-blue relative my-2 ${className}`}
      onClick={onClick}
    >
      <div className="absolute left-5">
        <img
          src={svgIconUrl}
          alt={title}
          className={`w-6 h-6 ${logoClassName}`}
        />
      </div>
      <div className="font-semibold ml-4">{title}</div>
    </div>
  );
}
