import { useMemo } from "react";
import { ApolloClient, from, split } from "@apollo/client"; // NOSONAR
import { getMainDefinition } from "@apollo/client/utilities"; // NOSONAR
import merge from "deepmerge"; // NOSONAR
import isEqual from "lodash/isEqual"; // NOSONAR
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../constants/common";
import { WebSocketLink } from "@apollo/client/link/ws";
import { clientTypeDefs } from "../graphql/clientSchema";
import { cache } from "../graphql/cache";
import { setContext } from "@apollo/client/link/context";
import { nanoid } from "nanoid";
import { createUploadLink } from "apollo-upload-client";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

const isBrowser = process.browser;

const buildApolloLinks = () => {
  const sessionId = nanoid();
  const buildWebSocketLink = () => {
    return new WebSocketLink({
      uri: process.env.NEXT_PUBLIC_WEBSOCKET_ABSOLUTE_URL,
      options: {
        reconnect: true,
        connectionParams: {
          userToken: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN),
          sessionid: sessionId,
        },
      },
    });
  };

  const wsLink = isBrowser ? buildWebSocketLink() : null;

  const uploadLink = createUploadLink({ uri: process.env.NEXT_PUBLIC_APP_URL });

  const authLink = setContext((_, { headers }) => {
    const userToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    return {
      headers: {
        ...headers,
        authorization: userToken,
        sessionid: sessionId,
      },
    };
  });

  const splitLink = isBrowser
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        uploadLink
      )
    : uploadLink;

  return from([authLink, splitLink]);
};

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: buildApolloLinks(),
    cache,
    typeDefs: clientTypeDefs,
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}
