import { useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";
import {
  MONTHLY_PLAN_ID,
  MONTHLY_PLAN_PRICE,
  YEARLY_PLAN_ID,
  YEARLY_PLAN_PRICE,
} from "../../constants/common";
import { useIsProModalOpenedQuery } from "../../resolvers-types";
import BeforeProceedCheckout from "../beforeProceedCheckoutModal/BeforeProceedCheckoutModal";

export default function Plans() {
  const { data } = useIsProModalOpenedQuery();
  const isOpened = data?.isProModalOpened;
  const translate = useTranslations();
  const [selectedPlan, setSelectedPlan] = useState<string>(YEARLY_PLAN_ID);

  useEffect(() => {
    if (!isOpened) {
      setSelectedPlan(YEARLY_PLAN_ID);
    }
  }, [isOpened]);

  const getBgStyle = (selectedPlanName: string) => {
    return selectedPlanName === selectedPlan
      ? "bg-teal-500 drop-shadow-md border-0"
      : "bg-white hover:bg-slate-200";
  };

  const getTextStyle = (selectedPlanName: string) => {
    return selectedPlanName === selectedPlan ? "text-white" : "text-dark-blue";
  };
  return (
    <div className="w-full flex flex-col items-center justify-center mt-5">
      <div className="flex flex-col w-2/3">
        <div
          className={`cursor-pointer h-16 flex items-center w-full rounded-lg pr-2 pl-6 py-2 border border-dark-blue ${getBgStyle(
            YEARLY_PLAN_ID
          )} flex flex-row border-opacity-20`}
          onClick={() => setSelectedPlan(YEARLY_PLAN_ID)}
        >
          <div>
            <div className="flex flex-row items-center">
              <div
                className={`text-xl font-black w-32 ${getTextStyle(
                  YEARLY_PLAN_ID
                )}`}
              >
                {translate("1year")}
              </div>
              <div
                className={`text-sm font-bold ${getTextStyle(YEARLY_PLAN_ID)}`}
              >
                {YEARLY_PLAN_PRICE}/{translate("yearly")}
              </div>
            </div>
            <div className={`mt-1 text-xs ${getTextStyle(YEARLY_PLAN_ID)}`}>
              ({translate("yearlyDesc")})
            </div>
          </div>
        </div>
        <div
          className={`cursor-pointer h-16 flex items-center mt-4 w-full rounded-lg pr-2 pl-6 py-2 border border-dark-blue ${getBgStyle(
            MONTHLY_PLAN_ID
          )} flex flex-row border-opacity-20`}
          onClick={() => setSelectedPlan(MONTHLY_PLAN_ID)}
        >
          <div>
            <div className="flex flex-row items-center">
              <div
                className={`text-xl font-black w-32 ${getTextStyle(
                  MONTHLY_PLAN_ID
                )}`}
              >
                {translate("1month")}
              </div>
              <div
                className={`text-sm font-bold ${getTextStyle(MONTHLY_PLAN_ID)}`}
              >
                {MONTHLY_PLAN_PRICE}/{translate("monthly")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-10 flex flex-col items-center justify-center">
        <BeforeProceedCheckout selectedPlan={selectedPlan} />
      </div>
    </div>
  );
}
