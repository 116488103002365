import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import "@splidejs/splide/dist/css/splide.min.css";
import { useTranslations } from "next-intl";

interface BenefitType {
  title: string;
  desc: string;
}

export default function PremiumBenefits() {
  const translate = useTranslations();

  const benefits: BenefitType[] = [
    {
      title: translate("enableDarkMode") as string,
      desc: translate("enableDarkModeDesc") as string,
    },
    {
      title: translate("removeAds") as string,
      desc: translate("removeAdsDesc") as string,
    },
    {
      title: translate("supportDeveloper") as string,
      desc: translate("supportDeveloperDesc") as string,
    },
  ];

  return (
    <div className="mt-6 w-2/3 premium-benefits">
      <Splide
        options={{
          type: "loop",
          gap: "2rem",
          autoplay: true,
          height: "8rem",
        }}
      >
        {benefits.map((benefit, index) => {
          return (
            <SplideSlide key={`benefit-${index}`}>
              <div className="flex flex-col items-center">
                <div className="text-center text-lg text-main-blue font-semibold">
                  {benefit.title}
                </div>
                <div className="text-center text-sm mt-2 text-gray-500 w-3/4">
                  {benefit.desc}
                </div>
              </div>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}
