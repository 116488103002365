import React from "react";
import LoadingIcon from "../loadingIcon/LoadingIcon";
import { useIsGlobalLoadingQuery } from "../../resolvers-types";

export default function GlobalLoading() {
  const { data } = useIsGlobalLoadingQuery();
  const isLoading = data.isGlobalLoading;
  if (!isLoading) {
    return null;
  }
  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 display flex flex-col items-center justify-center">
      <LoadingIcon textColorClass="text-white" />
    </div>
  );
}
