import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddStoryPointInput = {
  storyPoint: Scalars['String'];
  roomId: Scalars['String'];
};

export type AdditionalLoginInfo = {
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type CheckRoomAccessPayload = {
  __typename?: 'CheckRoomAccessPayload';
  isCurrentUserJoined: Scalars['Boolean'];
  connectionStatus?: Maybe<ConnectionStatus>;
  currentPlayer?: Maybe<Player>;
};

export enum ConnectionStatus {
  Joined = 'JOINED',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Idle = 'IDLE',
  Blur = 'BLUR',
  DisconnectedByAnotherSession = 'DISCONNECTED_BY_ANOTHER_SESSION',
  Left = 'LEFT',
  Kicked = 'KICKED'
}

export type CreateRoomInput = {
  name: Scalars['String'];
  roomType?: Maybe<RoomType>;
};


export type DisconnectUserFromRoomInput = {
  roomId: Scalars['String'];
};

export type Edge = {
  __typename?: 'Edge';
  cursor: Scalars['String'];
  node: Node;
};

export type EditUserProfileInput = {
  displayName: Scalars['String'];
  id: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type JoinRoomInput = {
  roomId: Scalars['String'];
};

export type JoinRoomViaNumberInput = {
  roomNumber: Scalars['Int'];
};

export type KickPlayerInput = {
  playerId: Scalars['Int'];
  roomId: Scalars['String'];
};

export type LeaveRoomInput = {
  roomId: Scalars['String'];
};

export type LoginWithGoogleInput = {
  email: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  additionalLoginInfo?: Maybe<AdditionalLoginInfo>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['Int'];
  content: Scalars['String'];
  player: Player;
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  leaveRoom: Response;
  createRoom: Room;
  joinRoom: Player;
  loginWithGoogle: User;
  addStoryPoint: Player;
  updateConnectionStatus: Player;
  removeEstimates: Array<Player>;
  updateEstimationVisibility: RoomConfig;
  editUserProfile: User;
  updateRoomInfo: Room;
  disconnectUserFromRoom: Response;
  disconnectUser: Response;
  subscribePro: Response;
  updateProfilePic: User;
  sendMessage: Message;
  randomizePlayer?: Maybe<Player>;
  joinRoomViaNumber: Player;
  updateSvgAvatar: User;
  updateRoomHost: RoomConfig;
  kickPlayer: Response;
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationLeaveRoomArgs = {
  input: LeaveRoomInput;
};


export type MutationCreateRoomArgs = {
  input: CreateRoomInput;
};


export type MutationJoinRoomArgs = {
  input: JoinRoomInput;
};


export type MutationLoginWithGoogleArgs = {
  input: LoginWithGoogleInput;
};


export type MutationAddStoryPointArgs = {
  input: AddStoryPointInput;
};


export type MutationUpdateConnectionStatusArgs = {
  input: UpdateConnectionStatusInput;
};


export type MutationRemoveEstimatesArgs = {
  input: RemoveEstimatesInput;
};


export type MutationUpdateEstimationVisibilityArgs = {
  input: UpdateEstimationVisibilityInput;
};


export type MutationEditUserProfileArgs = {
  input: EditUserProfileInput;
};


export type MutationUpdateRoomInfoArgs = {
  input: UpdateRoomInfoInput;
};


export type MutationDisconnectUserFromRoomArgs = {
  input: DisconnectUserFromRoomInput;
};


export type MutationSubscribeProArgs = {
  input: SubscribeProInput;
};


export type MutationUpdateProfilePicArgs = {
  file: Scalars['Upload'];
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationRandomizePlayerArgs = {
  input: RandomizePlayerInput;
};


export type MutationJoinRoomViaNumberArgs = {
  input: JoinRoomViaNumberInput;
};


export type MutationUpdateSvgAvatarArgs = {
  input: UpdateSvgAvatarInput;
};


export type MutationUpdateRoomHostArgs = {
  input: UpdateRoomHostInput;
};


export type MutationKickPlayerArgs = {
  input: KickPlayerInput;
};

export type Node = Message | User;

export type OnboardingModal = {
  __typename?: 'OnboardingModal';
  isOpened: Scalars['Boolean'];
  title: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type PaginationResponse = {
  __typename?: 'PaginationResponse';
  edges?: Maybe<Array<Edge>>;
  pageInfo: PageInfo;
};

export type Player = {
  __typename?: 'Player';
  id: Scalars['Int'];
  roomId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  user: User;
  isHost: Scalars['Boolean'];
  connectionStatus?: Maybe<ConnectionStatus>;
  storyPoint?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  isCreateEstimateRoomModalVisible: Scalars['Boolean'];
  isFAQOpened: Scalars['Boolean'];
  isGlobalLoading: Scalars['Boolean'];
  isLoginModalOpened: Scalars['Boolean'];
  isOnboardingModalOpened: OnboardingModal;
  isProModalOpened: Scalars['Boolean'];
  joinedRandomPickerRooms: Array<Room>;
  joinedRooms: Array<Room>;
  playersInRoom: Array<Player>;
  room: Room;
  roomAccess: CheckRoomAccessPayload;
  roomInfoViaNumber: Room;
  roomMessages: PaginationResponse;
  rooms: Array<Room>;
  subInfo?: Maybe<SubscriptionInfo>;
  webSubValidation?: Maybe<User>;
  wheelMeRooms?: Maybe<Array<Room>>;
};


export type QueryPlayersInRoomArgs = {
  roomId: Scalars['String'];
};


export type QueryRoomArgs = {
  uniqId: Scalars['String'];
};


export type QueryRoomAccessArgs = {
  roomId: Scalars['String'];
};


export type QueryRoomInfoViaNumberArgs = {
  roomNumber: Scalars['Int'];
};


export type QueryRoomMessagesArgs = {
  roomId: Scalars['String'];
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};


export type QueryWebSubValidationArgs = {
  input: WebSubValidationInput;
};

export type RandomizePlayerInput = {
  roomId: Scalars['String'];
};

export type RemoveEstimatesInput = {
  roomId: Scalars['String'];
};

export type Response = {
  __typename?: 'Response';
  success: Scalars['Boolean'];
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['String'];
  name: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  uniqId: Scalars['String'];
  roomConfig?: Maybe<RoomConfig>;
  players?: Maybe<Array<Player>>;
  roomNumber?: Maybe<Scalars['Int']>;
};

export type RoomConfig = {
  __typename?: 'RoomConfig';
  id: Scalars['Int'];
  isEstimationVisible: Scalars['Boolean'];
  hostPlayerId?: Maybe<Scalars['Int']>;
};

export type RoomHostInfo = {
  __typename?: 'RoomHostInfo';
  newHost: Player;
  oldHost: Player;
};

export enum RoomType {
  Estimate = 'ESTIMATE',
  Wheelme = 'WHEELME',
  Randompicker = 'RANDOMPICKER'
}

export type SendMessageInput = {
  content: Scalars['String'];
  roomId: Scalars['String'];
  playerId: Scalars['Int'];
};

export enum SubPlatform {
  Apple = 'APPLE',
  Google = 'GOOGLE',
  Chargebee = 'CHARGEBEE'
}

export enum SubStatus {
  Cancelled = 'CANCELLED',
  Active = 'ACTIVE',
  Paused = 'PAUSED'
}

export enum SubType {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type SubscribeProInput = {
  receipt: Scalars['String'];
  subPlatform: SubPlatform;
  subType: SubType;
  isTest: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  room: Room;
  playerJoined: Player;
  playerLeft: Player;
  storyPointAdded: Player;
  connectionStatusUpdated: Player;
  roomConfigUpdated: RoomConfig;
  estimatesRemoved: Array<Player>;
  roomInfoUpdated: Room;
  roomMessageSent: Message;
  randomPlayerChosen: Player;
  playerKicked: Player;
};


export type SubscriptionRoomArgs = {
  id: Scalars['String'];
};


export type SubscriptionPlayerJoinedArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionPlayerLeftArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionStoryPointAddedArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionConnectionStatusUpdatedArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionRoomConfigUpdatedArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionEstimatesRemovedArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionRoomInfoUpdatedArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionRoomMessageSentArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionRandomPlayerChosenArgs = {
  roomId: Scalars['String'];
};


export type SubscriptionPlayerKickedArgs = {
  roomId: Scalars['String'];
};

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  id: Scalars['Int'];
  purchaseDateMs: Scalars['String'];
  expiresDateMs: Scalars['String'];
  subPlatform: SubPlatform;
  subType: SubType;
  subStatus: SubStatus;
};

export type UpdateConnectionStatusInput = {
  roomId: Scalars['String'];
  connectionStatus?: Maybe<ConnectionStatus>;
};

export type UpdateEstimationVisibilityInput = {
  roomId: Scalars['String'];
  isEstimationVisible: Scalars['Boolean'];
};

export type UpdateRoomHostInput = {
  roomId: Scalars['String'];
  newHostPlayerId: Scalars['Int'];
};

export type UpdateRoomInfoInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateSvgAvatarInput = {
  svg: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  avatarUrl: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
  isPremium?: Maybe<Scalars['Boolean']>;
};

export type WebSubValidationInput = {
  subId: Scalars['String'];
  invoiceId: Scalars['String'];
  isTest?: Maybe<Scalars['Int']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddStoryPointInput: AddStoryPointInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  AdditionalLoginInfo: AdditionalLoginInfo;
  CheckRoomAccessPayload: ResolverTypeWrapper<CheckRoomAccessPayload>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ConnectionStatus: ConnectionStatus;
  CreateRoomInput: CreateRoomInput;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DisconnectUserFromRoomInput: DisconnectUserFromRoomInput;
  Edge: ResolverTypeWrapper<Omit<Edge, 'node'> & { node: ResolversTypes['Node'] }>;
  EditUserProfileInput: EditUserProfileInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  File: ResolverTypeWrapper<File>;
  JoinRoomInput: JoinRoomInput;
  JoinRoomViaNumberInput: JoinRoomViaNumberInput;
  KickPlayerInput: KickPlayerInput;
  LeaveRoomInput: LeaveRoomInput;
  LoginWithGoogleInput: LoginWithGoogleInput;
  Message: ResolverTypeWrapper<Message>;
  Mutation: ResolverTypeWrapper<{}>;
  Node: ResolversTypes['Message'] | ResolversTypes['User'];
  OnboardingModal: ResolverTypeWrapper<OnboardingModal>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaginationResponse: ResolverTypeWrapper<PaginationResponse>;
  Player: ResolverTypeWrapper<Player>;
  Query: ResolverTypeWrapper<{}>;
  RandomizePlayerInput: RandomizePlayerInput;
  RemoveEstimatesInput: RemoveEstimatesInput;
  Response: ResolverTypeWrapper<Response>;
  Room: ResolverTypeWrapper<Room>;
  RoomConfig: ResolverTypeWrapper<RoomConfig>;
  RoomHostInfo: ResolverTypeWrapper<RoomHostInfo>;
  RoomType: RoomType;
  SendMessageInput: SendMessageInput;
  SubPlatform: SubPlatform;
  SubStatus: SubStatus;
  SubType: SubType;
  SubscribeProInput: SubscribeProInput;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionInfo: ResolverTypeWrapper<SubscriptionInfo>;
  UpdateConnectionStatusInput: UpdateConnectionStatusInput;
  UpdateEstimationVisibilityInput: UpdateEstimationVisibilityInput;
  UpdateRoomHostInput: UpdateRoomHostInput;
  UpdateRoomInfoInput: UpdateRoomInfoInput;
  UpdateSvgAvatarInput: UpdateSvgAvatarInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  User: ResolverTypeWrapper<User>;
  WebSubValidationInput: WebSubValidationInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddStoryPointInput: AddStoryPointInput;
  String: Scalars['String'];
  AdditionalLoginInfo: AdditionalLoginInfo;
  CheckRoomAccessPayload: CheckRoomAccessPayload;
  Boolean: Scalars['Boolean'];
  CreateRoomInput: CreateRoomInput;
  Date: Scalars['Date'];
  DisconnectUserFromRoomInput: DisconnectUserFromRoomInput;
  Edge: Omit<Edge, 'node'> & { node: ResolversParentTypes['Node'] };
  EditUserProfileInput: EditUserProfileInput;
  Int: Scalars['Int'];
  File: File;
  JoinRoomInput: JoinRoomInput;
  JoinRoomViaNumberInput: JoinRoomViaNumberInput;
  KickPlayerInput: KickPlayerInput;
  LeaveRoomInput: LeaveRoomInput;
  LoginWithGoogleInput: LoginWithGoogleInput;
  Message: Message;
  Mutation: {};
  Node: ResolversParentTypes['Message'] | ResolversParentTypes['User'];
  OnboardingModal: OnboardingModal;
  PageInfo: PageInfo;
  PaginationResponse: PaginationResponse;
  Player: Player;
  Query: {};
  RandomizePlayerInput: RandomizePlayerInput;
  RemoveEstimatesInput: RemoveEstimatesInput;
  Response: Response;
  Room: Room;
  RoomConfig: RoomConfig;
  RoomHostInfo: RoomHostInfo;
  SendMessageInput: SendMessageInput;
  SubscribeProInput: SubscribeProInput;
  Subscription: {};
  SubscriptionInfo: SubscriptionInfo;
  UpdateConnectionStatusInput: UpdateConnectionStatusInput;
  UpdateEstimationVisibilityInput: UpdateEstimationVisibilityInput;
  UpdateRoomHostInput: UpdateRoomHostInput;
  UpdateRoomInfoInput: UpdateRoomInfoInput;
  UpdateSvgAvatarInput: UpdateSvgAvatarInput;
  Upload: Scalars['Upload'];
  User: User;
  WebSubValidationInput: WebSubValidationInput;
};

export type CheckRoomAccessPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckRoomAccessPayload'] = ResolversParentTypes['CheckRoomAccessPayload']> = {
  isCurrentUserJoined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  connectionStatus?: Resolver<Maybe<ResolversTypes['ConnectionStatus']>, ParentType, ContextType>;
  currentPlayer?: Resolver<Maybe<ResolversTypes['Player']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type EdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Edge'] = ResolversParentTypes['Edge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Node'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File']> = {
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimetype?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  encoding?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player?: Resolver<ResolversTypes['Player'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'email' | 'name'>>;
  leaveRoom?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationLeaveRoomArgs, 'input'>>;
  createRoom?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<MutationCreateRoomArgs, 'input'>>;
  joinRoom?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationJoinRoomArgs, 'input'>>;
  loginWithGoogle?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationLoginWithGoogleArgs, 'input'>>;
  addStoryPoint?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationAddStoryPointArgs, 'input'>>;
  updateConnectionStatus?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationUpdateConnectionStatusArgs, 'input'>>;
  removeEstimates?: Resolver<Array<ResolversTypes['Player']>, ParentType, ContextType, RequireFields<MutationRemoveEstimatesArgs, 'input'>>;
  updateEstimationVisibility?: Resolver<ResolversTypes['RoomConfig'], ParentType, ContextType, RequireFields<MutationUpdateEstimationVisibilityArgs, 'input'>>;
  editUserProfile?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationEditUserProfileArgs, 'input'>>;
  updateRoomInfo?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<MutationUpdateRoomInfoArgs, 'input'>>;
  disconnectUserFromRoom?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationDisconnectUserFromRoomArgs, 'input'>>;
  disconnectUser?: Resolver<ResolversTypes['Response'], ParentType, ContextType>;
  subscribePro?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationSubscribeProArgs, 'input'>>;
  updateProfilePic?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateProfilePicArgs, 'file'>>;
  sendMessage?: Resolver<ResolversTypes['Message'], ParentType, ContextType, RequireFields<MutationSendMessageArgs, 'input'>>;
  randomizePlayer?: Resolver<Maybe<ResolversTypes['Player']>, ParentType, ContextType, RequireFields<MutationRandomizePlayerArgs, 'input'>>;
  joinRoomViaNumber?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationJoinRoomViaNumberArgs, 'input'>>;
  updateSvgAvatar?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateSvgAvatarArgs, 'input'>>;
  updateRoomHost?: Resolver<ResolversTypes['RoomConfig'], ParentType, ContextType, RequireFields<MutationUpdateRoomHostArgs, 'input'>>;
  kickPlayer?: Resolver<ResolversTypes['Response'], ParentType, ContextType, RequireFields<MutationKickPlayerArgs, 'input'>>;
};

export type NodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']> = {
  __resolveType: TypeResolveFn<'Message' | 'User', ParentType, ContextType>;
};

export type OnboardingModalResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingModal'] = ResolversParentTypes['OnboardingModal']> = {
  isOpened?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginationResponse'] = ResolversParentTypes['PaginationResponse']> = {
  edges?: Resolver<Maybe<Array<ResolversTypes['Edge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Player'] = ResolversParentTypes['Player']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  isHost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  connectionStatus?: Resolver<Maybe<ResolversTypes['ConnectionStatus']>, ParentType, ContextType>;
  storyPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  currentUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  isCreateEstimateRoomModalVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFAQOpened?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isGlobalLoading?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLoginModalOpened?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOnboardingModalOpened?: Resolver<ResolversTypes['OnboardingModal'], ParentType, ContextType>;
  isProModalOpened?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  joinedRandomPickerRooms?: Resolver<Array<ResolversTypes['Room']>, ParentType, ContextType>;
  joinedRooms?: Resolver<Array<ResolversTypes['Room']>, ParentType, ContextType>;
  playersInRoom?: Resolver<Array<ResolversTypes['Player']>, ParentType, ContextType, RequireFields<QueryPlayersInRoomArgs, 'roomId'>>;
  room?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<QueryRoomArgs, 'uniqId'>>;
  roomAccess?: Resolver<ResolversTypes['CheckRoomAccessPayload'], ParentType, ContextType, RequireFields<QueryRoomAccessArgs, 'roomId'>>;
  roomInfoViaNumber?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<QueryRoomInfoViaNumberArgs, 'roomNumber'>>;
  roomMessages?: Resolver<ResolversTypes['PaginationResponse'], ParentType, ContextType, RequireFields<QueryRoomMessagesArgs, 'roomId' | 'first'>>;
  rooms?: Resolver<Array<ResolversTypes['Room']>, ParentType, ContextType>;
  subInfo?: Resolver<Maybe<ResolversTypes['SubscriptionInfo']>, ParentType, ContextType>;
  webSubValidation?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryWebSubValidationArgs, 'input'>>;
  wheelMeRooms?: Resolver<Maybe<Array<ResolversTypes['Room']>>, ParentType, ContextType>;
};

export type ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoomResolvers<ContextType = any, ParentType extends ResolversParentTypes['Room'] = ResolversParentTypes['Room']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  uniqId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roomConfig?: Resolver<Maybe<ResolversTypes['RoomConfig']>, ParentType, ContextType>;
  players?: Resolver<Maybe<Array<ResolversTypes['Player']>>, ParentType, ContextType>;
  roomNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoomConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoomConfig'] = ResolversParentTypes['RoomConfig']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isEstimationVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hostPlayerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoomHostInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoomHostInfo'] = ResolversParentTypes['RoomHostInfo']> = {
  newHost?: Resolver<ResolversTypes['Player'], ParentType, ContextType>;
  oldHost?: Resolver<ResolversTypes['Player'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  room?: SubscriptionResolver<ResolversTypes['Room'], "room", ParentType, ContextType, RequireFields<SubscriptionRoomArgs, 'id'>>;
  playerJoined?: SubscriptionResolver<ResolversTypes['Player'], "playerJoined", ParentType, ContextType, RequireFields<SubscriptionPlayerJoinedArgs, 'roomId'>>;
  playerLeft?: SubscriptionResolver<ResolversTypes['Player'], "playerLeft", ParentType, ContextType, RequireFields<SubscriptionPlayerLeftArgs, 'roomId'>>;
  storyPointAdded?: SubscriptionResolver<ResolversTypes['Player'], "storyPointAdded", ParentType, ContextType, RequireFields<SubscriptionStoryPointAddedArgs, 'roomId'>>;
  connectionStatusUpdated?: SubscriptionResolver<ResolversTypes['Player'], "connectionStatusUpdated", ParentType, ContextType, RequireFields<SubscriptionConnectionStatusUpdatedArgs, 'roomId'>>;
  roomConfigUpdated?: SubscriptionResolver<ResolversTypes['RoomConfig'], "roomConfigUpdated", ParentType, ContextType, RequireFields<SubscriptionRoomConfigUpdatedArgs, 'roomId'>>;
  estimatesRemoved?: SubscriptionResolver<Array<ResolversTypes['Player']>, "estimatesRemoved", ParentType, ContextType, RequireFields<SubscriptionEstimatesRemovedArgs, 'roomId'>>;
  roomInfoUpdated?: SubscriptionResolver<ResolversTypes['Room'], "roomInfoUpdated", ParentType, ContextType, RequireFields<SubscriptionRoomInfoUpdatedArgs, 'roomId'>>;
  roomMessageSent?: SubscriptionResolver<ResolversTypes['Message'], "roomMessageSent", ParentType, ContextType, RequireFields<SubscriptionRoomMessageSentArgs, 'roomId'>>;
  randomPlayerChosen?: SubscriptionResolver<ResolversTypes['Player'], "randomPlayerChosen", ParentType, ContextType, RequireFields<SubscriptionRandomPlayerChosenArgs, 'roomId'>>;
  playerKicked?: SubscriptionResolver<ResolversTypes['Player'], "playerKicked", ParentType, ContextType, RequireFields<SubscriptionPlayerKickedArgs, 'roomId'>>;
};

export type SubscriptionInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionInfo'] = ResolversParentTypes['SubscriptionInfo']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  purchaseDateMs?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiresDateMs?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subPlatform?: Resolver<ResolversTypes['SubPlatform'], ParentType, ContextType>;
  subType?: Resolver<ResolversTypes['SubType'], ParentType, ContextType>;
  subStatus?: Resolver<ResolversTypes['SubStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPremium?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  CheckRoomAccessPayload?: CheckRoomAccessPayloadResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Edge?: EdgeResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  OnboardingModal?: OnboardingModalResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaginationResponse?: PaginationResponseResolvers<ContextType>;
  Player?: PlayerResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Response?: ResponseResolvers<ContextType>;
  Room?: RoomResolvers<ContextType>;
  RoomConfig?: RoomConfigResolvers<ContextType>;
  RoomHostInfo?: RoomHostInfoResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionInfo?: SubscriptionInfoResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export type IsGlobalLoadingQueryVariables = Exact<{ [key: string]: never; }>;


export type IsGlobalLoadingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isGlobalLoading'>
);

export type IsCreateEstimateRoomModalVisibleQueryVariables = Exact<{ [key: string]: never; }>;


export type IsCreateEstimateRoomModalVisibleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isCreateEstimateRoomModalVisible'>
);

export type IsProModalOpenedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsProModalOpenedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isProModalOpened'>
);

export type IsFaqOpenedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsFaqOpenedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isFAQOpened'>
);

export type IsOnboardingModalOpenedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsOnboardingModalOpenedQuery = (
  { __typename?: 'Query' }
  & { isOnboardingModalOpened: (
    { __typename?: 'OnboardingModal' }
    & Pick<OnboardingModal, 'isOpened' | 'title'>
  ) }
);

export type IsLoginModalOpenedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsLoginModalOpenedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isLoginModalOpened'>
);

export type PlayerInRoomFragment = (
  { __typename?: 'Player' }
  & Pick<Player, 'id' | 'isHost' | 'storyPoint' | 'connectionStatus'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'avatarUrl'>
  ) }
);

export type PlayerBasicInfoFragment = (
  { __typename?: 'Player' }
  & Pick<Player, 'id' | 'storyPoint' | 'connectionStatus'>
);

export type CreateRoomMutationVariables = Exact<{
  createRoomInput: CreateRoomInput;
}>;


export type CreateRoomMutation = (
  { __typename?: 'Mutation' }
  & { createRoom: (
    { __typename?: 'Room' }
    & Pick<Room, 'id' | 'name' | 'uniqId'>
  ) }
);

export type LeaveRoomMutationVariables = Exact<{
  input: LeaveRoomInput;
}>;


export type LeaveRoomMutation = (
  { __typename?: 'Mutation' }
  & { leaveRoom: (
    { __typename?: 'Response' }
    & Pick<Response, 'success'>
  ) }
);

export type JoinRoomMutationVariables = Exact<{
  input: JoinRoomInput;
}>;


export type JoinRoomMutation = (
  { __typename?: 'Mutation' }
  & { joinRoom: (
    { __typename?: 'Player' }
    & Pick<Player, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ) }
  ) }
);

export type AddStoryPointMutationVariables = Exact<{
  input: AddStoryPointInput;
}>;


export type AddStoryPointMutation = (
  { __typename?: 'Mutation' }
  & { addStoryPoint: (
    { __typename?: 'Player' }
    & PlayerBasicInfoFragment
  ) }
);

export type UpdateConnectionStatusMutationVariables = Exact<{
  input: UpdateConnectionStatusInput;
}>;


export type UpdateConnectionStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateConnectionStatus: (
    { __typename?: 'Player' }
    & PlayerBasicInfoFragment
  ) }
);

export type UpdateEstimationVisibilityMutationVariables = Exact<{
  input: UpdateEstimationVisibilityInput;
}>;


export type UpdateEstimationVisibilityMutation = (
  { __typename?: 'Mutation' }
  & { updateEstimationVisibility: (
    { __typename?: 'RoomConfig' }
    & Pick<RoomConfig, 'id' | 'isEstimationVisible'>
  ) }
);

export type RemoveEstimatesMutationVariables = Exact<{
  input: RemoveEstimatesInput;
}>;


export type RemoveEstimatesMutation = (
  { __typename?: 'Mutation' }
  & { removeEstimates: Array<(
    { __typename?: 'Player' }
    & PlayerBasicInfoFragment
  )> }
);

export type UpdateRoomInfoMutationVariables = Exact<{
  input: UpdateRoomInfoInput;
}>;


export type UpdateRoomInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateRoomInfo: (
    { __typename?: 'Room' }
    & Pick<Room, 'id' | 'name'>
  ) }
);

export type DisconnectUserFromRoomMutationVariables = Exact<{
  input: DisconnectUserFromRoomInput;
}>;


export type DisconnectUserFromRoomMutation = (
  { __typename?: 'Mutation' }
  & { disconnectUserFromRoom: (
    { __typename?: 'Response' }
    & Pick<Response, 'success'>
  ) }
);

export type KickPlayerMutationVariables = Exact<{
  input: KickPlayerInput;
}>;


export type KickPlayerMutation = (
  { __typename?: 'Mutation' }
  & { kickPlayer: (
    { __typename?: 'Response' }
    & Pick<Response, 'success'>
  ) }
);

export type GetRoomQueryVariables = Exact<{
  uniqId: Scalars['String'];
}>;


export type GetRoomQuery = (
  { __typename?: 'Query' }
  & { room: (
    { __typename?: 'Room' }
    & Pick<Room, 'id' | 'name'>
    & { roomConfig?: Maybe<(
      { __typename?: 'RoomConfig' }
      & Pick<RoomConfig, 'id' | 'isEstimationVisible' | 'hostPlayerId'>
    )>, players?: Maybe<Array<(
      { __typename?: 'Player' }
      & Pick<Player, 'id'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'avatarUrl'>
      ) }
    )>> }
  ) }
);

export type GetRoomAccessQueryVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type GetRoomAccessQuery = (
  { __typename?: 'Query' }
  & { roomAccess: (
    { __typename?: 'CheckRoomAccessPayload' }
    & Pick<CheckRoomAccessPayload, 'isCurrentUserJoined' | 'connectionStatus'>
  ) }
);

export type GetPlayersListQueryVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type GetPlayersListQuery = (
  { __typename?: 'Query' }
  & { playersInRoom: Array<(
    { __typename?: 'Player' }
    & PlayerInRoomFragment
  )> }
);

export type GetJoinedRoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJoinedRoomsQuery = (
  { __typename?: 'Query' }
  & { joinedRooms: Array<(
    { __typename?: 'Room' }
    & Pick<Room, 'id' | 'name' | 'uniqId'>
    & { players?: Maybe<Array<(
      { __typename?: 'Player' }
      & Pick<Player, 'id'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'avatarUrl'>
      ) }
    )>> }
  )> }
);

export type OnPlayerJoinedSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnPlayerJoinedSubscription = (
  { __typename?: 'Subscription' }
  & { playerJoined: (
    { __typename?: 'Player' }
    & PlayerInRoomFragment
  ) }
);

export type OnPlayerLeftSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnPlayerLeftSubscription = (
  { __typename?: 'Subscription' }
  & { playerLeft: (
    { __typename?: 'Player' }
    & PlayerInRoomFragment
  ) }
);

export type OnStoryPointAddedSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnStoryPointAddedSubscription = (
  { __typename?: 'Subscription' }
  & { storyPointAdded: (
    { __typename?: 'Player' }
    & Pick<Player, 'id' | 'storyPoint'>
  ) }
);

export type OnConnectionStatusUpdatedSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnConnectionStatusUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { connectionStatusUpdated: (
    { __typename?: 'Player' }
    & Pick<Player, 'id' | 'connectionStatus'>
  ) }
);

export type OnRoomConfigUpdatedSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnRoomConfigUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { roomConfigUpdated: (
    { __typename?: 'RoomConfig' }
    & Pick<RoomConfig, 'id' | 'isEstimationVisible'>
  ) }
);

export type OnEstimatesRemovedSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnEstimatesRemovedSubscription = (
  { __typename?: 'Subscription' }
  & { estimatesRemoved: Array<(
    { __typename?: 'Player' }
    & Pick<Player, 'id' | 'storyPoint'>
  )> }
);

export type OnRoomInfoUpdatedSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnRoomInfoUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { roomInfoUpdated: (
    { __typename?: 'Room' }
    & Pick<Room, 'id' | 'name'>
  ) }
);

export type OnPlayerKickedSubscriptionVariables = Exact<{
  roomId: Scalars['String'];
}>;


export type OnPlayerKickedSubscription = (
  { __typename?: 'Subscription' }
  & { playerKicked: (
    { __typename?: 'Player' }
    & PlayerInRoomFragment
  ) }
);

export type EditUserProfileMutationVariables = Exact<{
  input: EditUserProfileInput;
}>;


export type EditUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { editUserProfile: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ) }
);

export type UpdateProfilePicMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UpdateProfilePicMutation = (
  { __typename?: 'Mutation' }
  & { updateProfilePic: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'avatarUrl'>
  ) }
);

export type DisconnectUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectUserMutation = (
  { __typename?: 'Mutation' }
  & { disconnectUser: (
    { __typename?: 'Response' }
    & Pick<Response, 'success'>
  ) }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'avatarUrl' | 'isPremium'>
  )> }
);

export type SubInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type SubInfoQuery = (
  { __typename?: 'Query' }
  & { subInfo?: Maybe<(
    { __typename?: 'SubscriptionInfo' }
    & Pick<SubscriptionInfo, 'id' | 'purchaseDateMs' | 'expiresDateMs' | 'subPlatform' | 'subType' | 'subStatus'>
  )> }
);

export type WebSubValidationQueryVariables = Exact<{
  input: WebSubValidationInput;
}>;


export type WebSubValidationQuery = (
  { __typename?: 'Query' }
  & { webSubValidation?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isPremium'>
  )> }
);

export type LoginWithGoogleMutationVariables = Exact<{
  input: LoginWithGoogleInput;
}>;


export type LoginWithGoogleMutation = (
  { __typename?: 'Mutation' }
  & { loginWithGoogle: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'accessToken' | 'displayName' | 'email' | 'avatarUrl' | 'isPremium'>
  ) }
);

export const PlayerInRoomFragmentDoc = gql`
    fragment PlayerInRoom on Player {
  id
  user {
    id
    displayName
    avatarUrl
  }
  isHost
  storyPoint
  connectionStatus
}
    `;
export const PlayerBasicInfoFragmentDoc = gql`
    fragment PlayerBasicInfo on Player {
  id
  storyPoint
  connectionStatus
}
    `;
export const IsGlobalLoadingDocument = gql`
    query isGlobalLoading {
  isGlobalLoading @client
}
    `;

/**
 * __useIsGlobalLoadingQuery__
 *
 * To run a query within a React component, call `useIsGlobalLoadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsGlobalLoadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsGlobalLoadingQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsGlobalLoadingQuery(baseOptions?: Apollo.QueryHookOptions<IsGlobalLoadingQuery, IsGlobalLoadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsGlobalLoadingQuery, IsGlobalLoadingQueryVariables>(IsGlobalLoadingDocument, options);
      }
export function useIsGlobalLoadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsGlobalLoadingQuery, IsGlobalLoadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsGlobalLoadingQuery, IsGlobalLoadingQueryVariables>(IsGlobalLoadingDocument, options);
        }
export type IsGlobalLoadingQueryHookResult = ReturnType<typeof useIsGlobalLoadingQuery>;
export type IsGlobalLoadingLazyQueryHookResult = ReturnType<typeof useIsGlobalLoadingLazyQuery>;
export type IsGlobalLoadingQueryResult = Apollo.QueryResult<IsGlobalLoadingQuery, IsGlobalLoadingQueryVariables>;
export const IsCreateEstimateRoomModalVisibleDocument = gql`
    query isCreateEstimateRoomModalVisible {
  isCreateEstimateRoomModalVisible @client
}
    `;

/**
 * __useIsCreateEstimateRoomModalVisibleQuery__
 *
 * To run a query within a React component, call `useIsCreateEstimateRoomModalVisibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsCreateEstimateRoomModalVisibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsCreateEstimateRoomModalVisibleQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsCreateEstimateRoomModalVisibleQuery(baseOptions?: Apollo.QueryHookOptions<IsCreateEstimateRoomModalVisibleQuery, IsCreateEstimateRoomModalVisibleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsCreateEstimateRoomModalVisibleQuery, IsCreateEstimateRoomModalVisibleQueryVariables>(IsCreateEstimateRoomModalVisibleDocument, options);
      }
export function useIsCreateEstimateRoomModalVisibleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsCreateEstimateRoomModalVisibleQuery, IsCreateEstimateRoomModalVisibleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsCreateEstimateRoomModalVisibleQuery, IsCreateEstimateRoomModalVisibleQueryVariables>(IsCreateEstimateRoomModalVisibleDocument, options);
        }
export type IsCreateEstimateRoomModalVisibleQueryHookResult = ReturnType<typeof useIsCreateEstimateRoomModalVisibleQuery>;
export type IsCreateEstimateRoomModalVisibleLazyQueryHookResult = ReturnType<typeof useIsCreateEstimateRoomModalVisibleLazyQuery>;
export type IsCreateEstimateRoomModalVisibleQueryResult = Apollo.QueryResult<IsCreateEstimateRoomModalVisibleQuery, IsCreateEstimateRoomModalVisibleQueryVariables>;
export const IsProModalOpenedDocument = gql`
    query isProModalOpened {
  isProModalOpened @client
}
    `;

/**
 * __useIsProModalOpenedQuery__
 *
 * To run a query within a React component, call `useIsProModalOpenedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsProModalOpenedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsProModalOpenedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsProModalOpenedQuery(baseOptions?: Apollo.QueryHookOptions<IsProModalOpenedQuery, IsProModalOpenedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsProModalOpenedQuery, IsProModalOpenedQueryVariables>(IsProModalOpenedDocument, options);
      }
export function useIsProModalOpenedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsProModalOpenedQuery, IsProModalOpenedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsProModalOpenedQuery, IsProModalOpenedQueryVariables>(IsProModalOpenedDocument, options);
        }
export type IsProModalOpenedQueryHookResult = ReturnType<typeof useIsProModalOpenedQuery>;
export type IsProModalOpenedLazyQueryHookResult = ReturnType<typeof useIsProModalOpenedLazyQuery>;
export type IsProModalOpenedQueryResult = Apollo.QueryResult<IsProModalOpenedQuery, IsProModalOpenedQueryVariables>;
export const IsFaqOpenedDocument = gql`
    query isFAQOpened {
  isFAQOpened @client
}
    `;

/**
 * __useIsFaqOpenedQuery__
 *
 * To run a query within a React component, call `useIsFaqOpenedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFaqOpenedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFaqOpenedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsFaqOpenedQuery(baseOptions?: Apollo.QueryHookOptions<IsFaqOpenedQuery, IsFaqOpenedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsFaqOpenedQuery, IsFaqOpenedQueryVariables>(IsFaqOpenedDocument, options);
      }
export function useIsFaqOpenedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsFaqOpenedQuery, IsFaqOpenedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsFaqOpenedQuery, IsFaqOpenedQueryVariables>(IsFaqOpenedDocument, options);
        }
export type IsFaqOpenedQueryHookResult = ReturnType<typeof useIsFaqOpenedQuery>;
export type IsFaqOpenedLazyQueryHookResult = ReturnType<typeof useIsFaqOpenedLazyQuery>;
export type IsFaqOpenedQueryResult = Apollo.QueryResult<IsFaqOpenedQuery, IsFaqOpenedQueryVariables>;
export const IsOnboardingModalOpenedDocument = gql`
    query isOnboardingModalOpened {
  isOnboardingModalOpened @client {
    isOpened
    title
  }
}
    `;

/**
 * __useIsOnboardingModalOpenedQuery__
 *
 * To run a query within a React component, call `useIsOnboardingModalOpenedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOnboardingModalOpenedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOnboardingModalOpenedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsOnboardingModalOpenedQuery(baseOptions?: Apollo.QueryHookOptions<IsOnboardingModalOpenedQuery, IsOnboardingModalOpenedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsOnboardingModalOpenedQuery, IsOnboardingModalOpenedQueryVariables>(IsOnboardingModalOpenedDocument, options);
      }
export function useIsOnboardingModalOpenedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsOnboardingModalOpenedQuery, IsOnboardingModalOpenedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsOnboardingModalOpenedQuery, IsOnboardingModalOpenedQueryVariables>(IsOnboardingModalOpenedDocument, options);
        }
export type IsOnboardingModalOpenedQueryHookResult = ReturnType<typeof useIsOnboardingModalOpenedQuery>;
export type IsOnboardingModalOpenedLazyQueryHookResult = ReturnType<typeof useIsOnboardingModalOpenedLazyQuery>;
export type IsOnboardingModalOpenedQueryResult = Apollo.QueryResult<IsOnboardingModalOpenedQuery, IsOnboardingModalOpenedQueryVariables>;
export const IsLoginModalOpenedDocument = gql`
    query isLoginModalOpened {
  isLoginModalOpened @client
}
    `;

/**
 * __useIsLoginModalOpenedQuery__
 *
 * To run a query within a React component, call `useIsLoginModalOpenedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoginModalOpenedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoginModalOpenedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoginModalOpenedQuery(baseOptions?: Apollo.QueryHookOptions<IsLoginModalOpenedQuery, IsLoginModalOpenedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsLoginModalOpenedQuery, IsLoginModalOpenedQueryVariables>(IsLoginModalOpenedDocument, options);
      }
export function useIsLoginModalOpenedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsLoginModalOpenedQuery, IsLoginModalOpenedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsLoginModalOpenedQuery, IsLoginModalOpenedQueryVariables>(IsLoginModalOpenedDocument, options);
        }
export type IsLoginModalOpenedQueryHookResult = ReturnType<typeof useIsLoginModalOpenedQuery>;
export type IsLoginModalOpenedLazyQueryHookResult = ReturnType<typeof useIsLoginModalOpenedLazyQuery>;
export type IsLoginModalOpenedQueryResult = Apollo.QueryResult<IsLoginModalOpenedQuery, IsLoginModalOpenedQueryVariables>;
export const CreateRoomDocument = gql`
    mutation CreateRoom($createRoomInput: CreateRoomInput!) {
  createRoom(input: $createRoomInput) {
    id
    name
    uniqId
  }
}
    `;
export type CreateRoomMutationFn = Apollo.MutationFunction<CreateRoomMutation, CreateRoomMutationVariables>;

/**
 * __useCreateRoomMutation__
 *
 * To run a mutation, you first call `useCreateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomMutation, { data, loading, error }] = useCreateRoomMutation({
 *   variables: {
 *      createRoomInput: // value for 'createRoomInput'
 *   },
 * });
 */
export function useCreateRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoomMutation, CreateRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoomMutation, CreateRoomMutationVariables>(CreateRoomDocument, options);
      }
export type CreateRoomMutationHookResult = ReturnType<typeof useCreateRoomMutation>;
export type CreateRoomMutationResult = Apollo.MutationResult<CreateRoomMutation>;
export type CreateRoomMutationOptions = Apollo.BaseMutationOptions<CreateRoomMutation, CreateRoomMutationVariables>;
export const LeaveRoomDocument = gql`
    mutation LeaveRoom($input: LeaveRoomInput!) {
  leaveRoom(input: $input) {
    success
  }
}
    `;
export type LeaveRoomMutationFn = Apollo.MutationFunction<LeaveRoomMutation, LeaveRoomMutationVariables>;

/**
 * __useLeaveRoomMutation__
 *
 * To run a mutation, you first call `useLeaveRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveRoomMutation, { data, loading, error }] = useLeaveRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveRoomMutation(baseOptions?: Apollo.MutationHookOptions<LeaveRoomMutation, LeaveRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveRoomMutation, LeaveRoomMutationVariables>(LeaveRoomDocument, options);
      }
export type LeaveRoomMutationHookResult = ReturnType<typeof useLeaveRoomMutation>;
export type LeaveRoomMutationResult = Apollo.MutationResult<LeaveRoomMutation>;
export type LeaveRoomMutationOptions = Apollo.BaseMutationOptions<LeaveRoomMutation, LeaveRoomMutationVariables>;
export const JoinRoomDocument = gql`
    mutation JoinRoom($input: JoinRoomInput!) {
  joinRoom(input: $input) {
    user {
      id
      displayName
    }
    id
  }
}
    `;
export type JoinRoomMutationFn = Apollo.MutationFunction<JoinRoomMutation, JoinRoomMutationVariables>;

/**
 * __useJoinRoomMutation__
 *
 * To run a mutation, you first call `useJoinRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinRoomMutation, { data, loading, error }] = useJoinRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinRoomMutation(baseOptions?: Apollo.MutationHookOptions<JoinRoomMutation, JoinRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinRoomMutation, JoinRoomMutationVariables>(JoinRoomDocument, options);
      }
export type JoinRoomMutationHookResult = ReturnType<typeof useJoinRoomMutation>;
export type JoinRoomMutationResult = Apollo.MutationResult<JoinRoomMutation>;
export type JoinRoomMutationOptions = Apollo.BaseMutationOptions<JoinRoomMutation, JoinRoomMutationVariables>;
export const AddStoryPointDocument = gql`
    mutation AddStoryPoint($input: AddStoryPointInput!) {
  addStoryPoint(input: $input) {
    ...PlayerBasicInfo
  }
}
    ${PlayerBasicInfoFragmentDoc}`;
export type AddStoryPointMutationFn = Apollo.MutationFunction<AddStoryPointMutation, AddStoryPointMutationVariables>;

/**
 * __useAddStoryPointMutation__
 *
 * To run a mutation, you first call `useAddStoryPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoryPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoryPointMutation, { data, loading, error }] = useAddStoryPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoryPointMutation(baseOptions?: Apollo.MutationHookOptions<AddStoryPointMutation, AddStoryPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoryPointMutation, AddStoryPointMutationVariables>(AddStoryPointDocument, options);
      }
export type AddStoryPointMutationHookResult = ReturnType<typeof useAddStoryPointMutation>;
export type AddStoryPointMutationResult = Apollo.MutationResult<AddStoryPointMutation>;
export type AddStoryPointMutationOptions = Apollo.BaseMutationOptions<AddStoryPointMutation, AddStoryPointMutationVariables>;
export const UpdateConnectionStatusDocument = gql`
    mutation UpdateConnectionStatus($input: UpdateConnectionStatusInput!) {
  updateConnectionStatus(input: $input) {
    ...PlayerBasicInfo
  }
}
    ${PlayerBasicInfoFragmentDoc}`;
export type UpdateConnectionStatusMutationFn = Apollo.MutationFunction<UpdateConnectionStatusMutation, UpdateConnectionStatusMutationVariables>;

/**
 * __useUpdateConnectionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionStatusMutation, { data, loading, error }] = useUpdateConnectionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectionStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConnectionStatusMutation, UpdateConnectionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConnectionStatusMutation, UpdateConnectionStatusMutationVariables>(UpdateConnectionStatusDocument, options);
      }
export type UpdateConnectionStatusMutationHookResult = ReturnType<typeof useUpdateConnectionStatusMutation>;
export type UpdateConnectionStatusMutationResult = Apollo.MutationResult<UpdateConnectionStatusMutation>;
export type UpdateConnectionStatusMutationOptions = Apollo.BaseMutationOptions<UpdateConnectionStatusMutation, UpdateConnectionStatusMutationVariables>;
export const UpdateEstimationVisibilityDocument = gql`
    mutation UpdateEstimationVisibility($input: UpdateEstimationVisibilityInput!) {
  updateEstimationVisibility(input: $input) {
    id
    isEstimationVisible
  }
}
    `;
export type UpdateEstimationVisibilityMutationFn = Apollo.MutationFunction<UpdateEstimationVisibilityMutation, UpdateEstimationVisibilityMutationVariables>;

/**
 * __useUpdateEstimationVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateEstimationVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimationVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimationVisibilityMutation, { data, loading, error }] = useUpdateEstimationVisibilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEstimationVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstimationVisibilityMutation, UpdateEstimationVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEstimationVisibilityMutation, UpdateEstimationVisibilityMutationVariables>(UpdateEstimationVisibilityDocument, options);
      }
export type UpdateEstimationVisibilityMutationHookResult = ReturnType<typeof useUpdateEstimationVisibilityMutation>;
export type UpdateEstimationVisibilityMutationResult = Apollo.MutationResult<UpdateEstimationVisibilityMutation>;
export type UpdateEstimationVisibilityMutationOptions = Apollo.BaseMutationOptions<UpdateEstimationVisibilityMutation, UpdateEstimationVisibilityMutationVariables>;
export const RemoveEstimatesDocument = gql`
    mutation RemoveEstimates($input: RemoveEstimatesInput!) {
  removeEstimates(input: $input) {
    ...PlayerBasicInfo
  }
}
    ${PlayerBasicInfoFragmentDoc}`;
export type RemoveEstimatesMutationFn = Apollo.MutationFunction<RemoveEstimatesMutation, RemoveEstimatesMutationVariables>;

/**
 * __useRemoveEstimatesMutation__
 *
 * To run a mutation, you first call `useRemoveEstimatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEstimatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEstimatesMutation, { data, loading, error }] = useRemoveEstimatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEstimatesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEstimatesMutation, RemoveEstimatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEstimatesMutation, RemoveEstimatesMutationVariables>(RemoveEstimatesDocument, options);
      }
export type RemoveEstimatesMutationHookResult = ReturnType<typeof useRemoveEstimatesMutation>;
export type RemoveEstimatesMutationResult = Apollo.MutationResult<RemoveEstimatesMutation>;
export type RemoveEstimatesMutationOptions = Apollo.BaseMutationOptions<RemoveEstimatesMutation, RemoveEstimatesMutationVariables>;
export const UpdateRoomInfoDocument = gql`
    mutation UpdateRoomInfo($input: UpdateRoomInfoInput!) {
  updateRoomInfo(input: $input) {
    id
    name
  }
}
    `;
export type UpdateRoomInfoMutationFn = Apollo.MutationFunction<UpdateRoomInfoMutation, UpdateRoomInfoMutationVariables>;

/**
 * __useUpdateRoomInfoMutation__
 *
 * To run a mutation, you first call `useUpdateRoomInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomInfoMutation, { data, loading, error }] = useUpdateRoomInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoomInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomInfoMutation, UpdateRoomInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomInfoMutation, UpdateRoomInfoMutationVariables>(UpdateRoomInfoDocument, options);
      }
export type UpdateRoomInfoMutationHookResult = ReturnType<typeof useUpdateRoomInfoMutation>;
export type UpdateRoomInfoMutationResult = Apollo.MutationResult<UpdateRoomInfoMutation>;
export type UpdateRoomInfoMutationOptions = Apollo.BaseMutationOptions<UpdateRoomInfoMutation, UpdateRoomInfoMutationVariables>;
export const DisconnectUserFromRoomDocument = gql`
    mutation DisconnectUserFromRoom($input: DisconnectUserFromRoomInput!) {
  disconnectUserFromRoom(input: $input) {
    success
  }
}
    `;
export type DisconnectUserFromRoomMutationFn = Apollo.MutationFunction<DisconnectUserFromRoomMutation, DisconnectUserFromRoomMutationVariables>;

/**
 * __useDisconnectUserFromRoomMutation__
 *
 * To run a mutation, you first call `useDisconnectUserFromRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectUserFromRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectUserFromRoomMutation, { data, loading, error }] = useDisconnectUserFromRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectUserFromRoomMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectUserFromRoomMutation, DisconnectUserFromRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectUserFromRoomMutation, DisconnectUserFromRoomMutationVariables>(DisconnectUserFromRoomDocument, options);
      }
export type DisconnectUserFromRoomMutationHookResult = ReturnType<typeof useDisconnectUserFromRoomMutation>;
export type DisconnectUserFromRoomMutationResult = Apollo.MutationResult<DisconnectUserFromRoomMutation>;
export type DisconnectUserFromRoomMutationOptions = Apollo.BaseMutationOptions<DisconnectUserFromRoomMutation, DisconnectUserFromRoomMutationVariables>;
export const KickPlayerDocument = gql`
    mutation KickPlayer($input: KickPlayerInput!) {
  kickPlayer(input: $input) {
    success
  }
}
    `;
export type KickPlayerMutationFn = Apollo.MutationFunction<KickPlayerMutation, KickPlayerMutationVariables>;

/**
 * __useKickPlayerMutation__
 *
 * To run a mutation, you first call `useKickPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKickPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kickPlayerMutation, { data, loading, error }] = useKickPlayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKickPlayerMutation(baseOptions?: Apollo.MutationHookOptions<KickPlayerMutation, KickPlayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KickPlayerMutation, KickPlayerMutationVariables>(KickPlayerDocument, options);
      }
export type KickPlayerMutationHookResult = ReturnType<typeof useKickPlayerMutation>;
export type KickPlayerMutationResult = Apollo.MutationResult<KickPlayerMutation>;
export type KickPlayerMutationOptions = Apollo.BaseMutationOptions<KickPlayerMutation, KickPlayerMutationVariables>;
export const GetRoomDocument = gql`
    query GetRoom($uniqId: String!) {
  room(uniqId: $uniqId) {
    id
    name
    roomConfig {
      id
      isEstimationVisible
      hostPlayerId
    }
    players {
      id
      user {
        id
        displayName
        avatarUrl
      }
    }
  }
}
    `;

/**
 * __useGetRoomQuery__
 *
 * To run a query within a React component, call `useGetRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomQuery({
 *   variables: {
 *      uniqId: // value for 'uniqId'
 *   },
 * });
 */
export function useGetRoomQuery(baseOptions: Apollo.QueryHookOptions<GetRoomQuery, GetRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomQuery, GetRoomQueryVariables>(GetRoomDocument, options);
      }
export function useGetRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomQuery, GetRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomQuery, GetRoomQueryVariables>(GetRoomDocument, options);
        }
export type GetRoomQueryHookResult = ReturnType<typeof useGetRoomQuery>;
export type GetRoomLazyQueryHookResult = ReturnType<typeof useGetRoomLazyQuery>;
export type GetRoomQueryResult = Apollo.QueryResult<GetRoomQuery, GetRoomQueryVariables>;
export const GetRoomAccessDocument = gql`
    query GetRoomAccess($roomId: String!) {
  roomAccess(roomId: $roomId) {
    isCurrentUserJoined
    connectionStatus
  }
}
    `;

/**
 * __useGetRoomAccessQuery__
 *
 * To run a query within a React component, call `useGetRoomAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomAccessQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetRoomAccessQuery(baseOptions: Apollo.QueryHookOptions<GetRoomAccessQuery, GetRoomAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomAccessQuery, GetRoomAccessQueryVariables>(GetRoomAccessDocument, options);
      }
export function useGetRoomAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomAccessQuery, GetRoomAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomAccessQuery, GetRoomAccessQueryVariables>(GetRoomAccessDocument, options);
        }
export type GetRoomAccessQueryHookResult = ReturnType<typeof useGetRoomAccessQuery>;
export type GetRoomAccessLazyQueryHookResult = ReturnType<typeof useGetRoomAccessLazyQuery>;
export type GetRoomAccessQueryResult = Apollo.QueryResult<GetRoomAccessQuery, GetRoomAccessQueryVariables>;
export const GetPlayersListDocument = gql`
    query GetPlayersList($roomId: String!) {
  playersInRoom(roomId: $roomId) {
    ...PlayerInRoom
  }
}
    ${PlayerInRoomFragmentDoc}`;

/**
 * __useGetPlayersListQuery__
 *
 * To run a query within a React component, call `useGetPlayersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersListQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetPlayersListQuery(baseOptions: Apollo.QueryHookOptions<GetPlayersListQuery, GetPlayersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlayersListQuery, GetPlayersListQueryVariables>(GetPlayersListDocument, options);
      }
export function useGetPlayersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlayersListQuery, GetPlayersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlayersListQuery, GetPlayersListQueryVariables>(GetPlayersListDocument, options);
        }
export type GetPlayersListQueryHookResult = ReturnType<typeof useGetPlayersListQuery>;
export type GetPlayersListLazyQueryHookResult = ReturnType<typeof useGetPlayersListLazyQuery>;
export type GetPlayersListQueryResult = Apollo.QueryResult<GetPlayersListQuery, GetPlayersListQueryVariables>;
export const GetJoinedRoomsDocument = gql`
    query GetJoinedRooms {
  joinedRooms {
    id
    name
    uniqId
    players {
      id
      user {
        id
        displayName
        avatarUrl
      }
    }
  }
}
    `;

/**
 * __useGetJoinedRoomsQuery__
 *
 * To run a query within a React component, call `useGetJoinedRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJoinedRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJoinedRoomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJoinedRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetJoinedRoomsQuery, GetJoinedRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJoinedRoomsQuery, GetJoinedRoomsQueryVariables>(GetJoinedRoomsDocument, options);
      }
export function useGetJoinedRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJoinedRoomsQuery, GetJoinedRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJoinedRoomsQuery, GetJoinedRoomsQueryVariables>(GetJoinedRoomsDocument, options);
        }
export type GetJoinedRoomsQueryHookResult = ReturnType<typeof useGetJoinedRoomsQuery>;
export type GetJoinedRoomsLazyQueryHookResult = ReturnType<typeof useGetJoinedRoomsLazyQuery>;
export type GetJoinedRoomsQueryResult = Apollo.QueryResult<GetJoinedRoomsQuery, GetJoinedRoomsQueryVariables>;
export const OnPlayerJoinedDocument = gql`
    subscription OnPlayerJoined($roomId: String!) {
  playerJoined(roomId: $roomId) {
    ...PlayerInRoom
  }
}
    ${PlayerInRoomFragmentDoc}`;

/**
 * __useOnPlayerJoinedSubscription__
 *
 * To run a query within a React component, call `useOnPlayerJoinedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPlayerJoinedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPlayerJoinedSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnPlayerJoinedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPlayerJoinedSubscription, OnPlayerJoinedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPlayerJoinedSubscription, OnPlayerJoinedSubscriptionVariables>(OnPlayerJoinedDocument, options);
      }
export type OnPlayerJoinedSubscriptionHookResult = ReturnType<typeof useOnPlayerJoinedSubscription>;
export type OnPlayerJoinedSubscriptionResult = Apollo.SubscriptionResult<OnPlayerJoinedSubscription>;
export const OnPlayerLeftDocument = gql`
    subscription OnPlayerLeft($roomId: String!) {
  playerLeft(roomId: $roomId) {
    ...PlayerInRoom
  }
}
    ${PlayerInRoomFragmentDoc}`;

/**
 * __useOnPlayerLeftSubscription__
 *
 * To run a query within a React component, call `useOnPlayerLeftSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPlayerLeftSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPlayerLeftSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnPlayerLeftSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPlayerLeftSubscription, OnPlayerLeftSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPlayerLeftSubscription, OnPlayerLeftSubscriptionVariables>(OnPlayerLeftDocument, options);
      }
export type OnPlayerLeftSubscriptionHookResult = ReturnType<typeof useOnPlayerLeftSubscription>;
export type OnPlayerLeftSubscriptionResult = Apollo.SubscriptionResult<OnPlayerLeftSubscription>;
export const OnStoryPointAddedDocument = gql`
    subscription OnStoryPointAdded($roomId: String!) {
  storyPointAdded(roomId: $roomId) {
    id
    storyPoint
  }
}
    `;

/**
 * __useOnStoryPointAddedSubscription__
 *
 * To run a query within a React component, call `useOnStoryPointAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnStoryPointAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnStoryPointAddedSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnStoryPointAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnStoryPointAddedSubscription, OnStoryPointAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnStoryPointAddedSubscription, OnStoryPointAddedSubscriptionVariables>(OnStoryPointAddedDocument, options);
      }
export type OnStoryPointAddedSubscriptionHookResult = ReturnType<typeof useOnStoryPointAddedSubscription>;
export type OnStoryPointAddedSubscriptionResult = Apollo.SubscriptionResult<OnStoryPointAddedSubscription>;
export const OnConnectionStatusUpdatedDocument = gql`
    subscription OnConnectionStatusUpdated($roomId: String!) {
  connectionStatusUpdated(roomId: $roomId) {
    id
    connectionStatus
  }
}
    `;

/**
 * __useOnConnectionStatusUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnConnectionStatusUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnConnectionStatusUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnConnectionStatusUpdatedSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnConnectionStatusUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnConnectionStatusUpdatedSubscription, OnConnectionStatusUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnConnectionStatusUpdatedSubscription, OnConnectionStatusUpdatedSubscriptionVariables>(OnConnectionStatusUpdatedDocument, options);
      }
export type OnConnectionStatusUpdatedSubscriptionHookResult = ReturnType<typeof useOnConnectionStatusUpdatedSubscription>;
export type OnConnectionStatusUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnConnectionStatusUpdatedSubscription>;
export const OnRoomConfigUpdatedDocument = gql`
    subscription OnRoomConfigUpdated($roomId: String!) {
  roomConfigUpdated(roomId: $roomId) {
    id
    isEstimationVisible
  }
}
    `;

/**
 * __useOnRoomConfigUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnRoomConfigUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRoomConfigUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRoomConfigUpdatedSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnRoomConfigUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnRoomConfigUpdatedSubscription, OnRoomConfigUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnRoomConfigUpdatedSubscription, OnRoomConfigUpdatedSubscriptionVariables>(OnRoomConfigUpdatedDocument, options);
      }
export type OnRoomConfigUpdatedSubscriptionHookResult = ReturnType<typeof useOnRoomConfigUpdatedSubscription>;
export type OnRoomConfigUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnRoomConfigUpdatedSubscription>;
export const OnEstimatesRemovedDocument = gql`
    subscription OnEstimatesRemoved($roomId: String!) {
  estimatesRemoved(roomId: $roomId) {
    id
    storyPoint
  }
}
    `;

/**
 * __useOnEstimatesRemovedSubscription__
 *
 * To run a query within a React component, call `useOnEstimatesRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnEstimatesRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnEstimatesRemovedSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnEstimatesRemovedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnEstimatesRemovedSubscription, OnEstimatesRemovedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnEstimatesRemovedSubscription, OnEstimatesRemovedSubscriptionVariables>(OnEstimatesRemovedDocument, options);
      }
export type OnEstimatesRemovedSubscriptionHookResult = ReturnType<typeof useOnEstimatesRemovedSubscription>;
export type OnEstimatesRemovedSubscriptionResult = Apollo.SubscriptionResult<OnEstimatesRemovedSubscription>;
export const OnRoomInfoUpdatedDocument = gql`
    subscription OnRoomInfoUpdated($roomId: String!) {
  roomInfoUpdated(roomId: $roomId) {
    id
    name
  }
}
    `;

/**
 * __useOnRoomInfoUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnRoomInfoUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRoomInfoUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRoomInfoUpdatedSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnRoomInfoUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnRoomInfoUpdatedSubscription, OnRoomInfoUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnRoomInfoUpdatedSubscription, OnRoomInfoUpdatedSubscriptionVariables>(OnRoomInfoUpdatedDocument, options);
      }
export type OnRoomInfoUpdatedSubscriptionHookResult = ReturnType<typeof useOnRoomInfoUpdatedSubscription>;
export type OnRoomInfoUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnRoomInfoUpdatedSubscription>;
export const OnPlayerKickedDocument = gql`
    subscription OnPlayerKicked($roomId: String!) {
  playerKicked(roomId: $roomId) {
    ...PlayerInRoom
  }
}
    ${PlayerInRoomFragmentDoc}`;

/**
 * __useOnPlayerKickedSubscription__
 *
 * To run a query within a React component, call `useOnPlayerKickedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPlayerKickedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPlayerKickedSubscription({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useOnPlayerKickedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPlayerKickedSubscription, OnPlayerKickedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPlayerKickedSubscription, OnPlayerKickedSubscriptionVariables>(OnPlayerKickedDocument, options);
      }
export type OnPlayerKickedSubscriptionHookResult = ReturnType<typeof useOnPlayerKickedSubscription>;
export type OnPlayerKickedSubscriptionResult = Apollo.SubscriptionResult<OnPlayerKickedSubscription>;
export const EditUserProfileDocument = gql`
    mutation EditUserProfile($input: EditUserProfileInput!) {
  editUserProfile(input: $input) {
    id
    displayName
  }
}
    `;
export type EditUserProfileMutationFn = Apollo.MutationFunction<EditUserProfileMutation, EditUserProfileMutationVariables>;

/**
 * __useEditUserProfileMutation__
 *
 * To run a mutation, you first call `useEditUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserProfileMutation, { data, loading, error }] = useEditUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<EditUserProfileMutation, EditUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserProfileMutation, EditUserProfileMutationVariables>(EditUserProfileDocument, options);
      }
export type EditUserProfileMutationHookResult = ReturnType<typeof useEditUserProfileMutation>;
export type EditUserProfileMutationResult = Apollo.MutationResult<EditUserProfileMutation>;
export type EditUserProfileMutationOptions = Apollo.BaseMutationOptions<EditUserProfileMutation, EditUserProfileMutationVariables>;
export const UpdateProfilePicDocument = gql`
    mutation UpdateProfilePic($file: Upload!) {
  updateProfilePic(file: $file) {
    id
    displayName
    avatarUrl
  }
}
    `;
export type UpdateProfilePicMutationFn = Apollo.MutationFunction<UpdateProfilePicMutation, UpdateProfilePicMutationVariables>;

/**
 * __useUpdateProfilePicMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePicMutation, { data, loading, error }] = useUpdateProfilePicMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateProfilePicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePicMutation, UpdateProfilePicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePicMutation, UpdateProfilePicMutationVariables>(UpdateProfilePicDocument, options);
      }
export type UpdateProfilePicMutationHookResult = ReturnType<typeof useUpdateProfilePicMutation>;
export type UpdateProfilePicMutationResult = Apollo.MutationResult<UpdateProfilePicMutation>;
export type UpdateProfilePicMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePicMutation, UpdateProfilePicMutationVariables>;
export const DisconnectUserDocument = gql`
    mutation DisconnectUser {
  disconnectUser {
    success
  }
}
    `;
export type DisconnectUserMutationFn = Apollo.MutationFunction<DisconnectUserMutation, DisconnectUserMutationVariables>;

/**
 * __useDisconnectUserMutation__
 *
 * To run a mutation, you first call `useDisconnectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectUserMutation, { data, loading, error }] = useDisconnectUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectUserMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectUserMutation, DisconnectUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectUserMutation, DisconnectUserMutationVariables>(DisconnectUserDocument, options);
      }
export type DisconnectUserMutationHookResult = ReturnType<typeof useDisconnectUserMutation>;
export type DisconnectUserMutationResult = Apollo.MutationResult<DisconnectUserMutation>;
export type DisconnectUserMutationOptions = Apollo.BaseMutationOptions<DisconnectUserMutation, DisconnectUserMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    id
    displayName
    email
    avatarUrl
    isPremium
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const SubInfoDocument = gql`
    query subInfo {
  subInfo {
    id
    purchaseDateMs
    expiresDateMs
    subPlatform
    subType
    subStatus
  }
}
    `;

/**
 * __useSubInfoQuery__
 *
 * To run a query within a React component, call `useSubInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubInfoQuery(baseOptions?: Apollo.QueryHookOptions<SubInfoQuery, SubInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubInfoQuery, SubInfoQueryVariables>(SubInfoDocument, options);
      }
export function useSubInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubInfoQuery, SubInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubInfoQuery, SubInfoQueryVariables>(SubInfoDocument, options);
        }
export type SubInfoQueryHookResult = ReturnType<typeof useSubInfoQuery>;
export type SubInfoLazyQueryHookResult = ReturnType<typeof useSubInfoLazyQuery>;
export type SubInfoQueryResult = Apollo.QueryResult<SubInfoQuery, SubInfoQueryVariables>;
export const WebSubValidationDocument = gql`
    query WebSubValidation($input: WebSubValidationInput!) {
  webSubValidation(input: $input) {
    id
    isPremium
  }
}
    `;

/**
 * __useWebSubValidationQuery__
 *
 * To run a query within a React component, call `useWebSubValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebSubValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebSubValidationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebSubValidationQuery(baseOptions: Apollo.QueryHookOptions<WebSubValidationQuery, WebSubValidationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebSubValidationQuery, WebSubValidationQueryVariables>(WebSubValidationDocument, options);
      }
export function useWebSubValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebSubValidationQuery, WebSubValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebSubValidationQuery, WebSubValidationQueryVariables>(WebSubValidationDocument, options);
        }
export type WebSubValidationQueryHookResult = ReturnType<typeof useWebSubValidationQuery>;
export type WebSubValidationLazyQueryHookResult = ReturnType<typeof useWebSubValidationLazyQuery>;
export type WebSubValidationQueryResult = Apollo.QueryResult<WebSubValidationQuery, WebSubValidationQueryVariables>;
export const LoginWithGoogleDocument = gql`
    mutation LoginWithGoogle($input: LoginWithGoogleInput!) {
  loginWithGoogle(input: $input) {
    id
    accessToken
    displayName
    email
    avatarUrl
    isPremium
  }
}
    `;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>(LoginWithGoogleDocument, options);
      }
export type LoginWithGoogleMutationHookResult = ReturnType<typeof useLoginWithGoogleMutation>;
export type LoginWithGoogleMutationResult = Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;