import { useTranslations } from "next-intl";
import React from "react";
import { APP_URL_CONFIG } from "../../constants/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

export default function DownloadSection() {
  const translate = useTranslations();

  return (
    <div className="flex flex-col items-center">
      <a
        className="flex flex-row items-center px-6 py-2 rounded-lg bg-gray-900 hover:bg-opacity-90 dark:hover:bg-opacity-70 w-64 cursor-pointer no-underline text-white hover:no-underline"
        href={APP_URL_CONFIG.deeplinkFallbackURLIOS}
        target="_blank"
      >
        <div className="flex items-center justify-center w-10 ">
          <FontAwesomeIcon icon={faApple} size="3x" className="scale-85" />
        </div>
        <div className="ml-3">
          <div className="text-xs">{translate("downloadItOn")}</div>
          <div className="text-2xl font-bold">App Store</div>
        </div>
      </a>
      <a
        className="flex flex-row items-center px-6 py-2 rounded-lg bg-gray-900 hover:bg-opacity-90 dark:hover:bg-opacity-70 w-64 cursor-pointer mt-3 no-underline text-white hover:no-underline"
        href={APP_URL_CONFIG.deeplinkFallbackURLAndroid}
        target="_blank"
      >
        <div className="flex items-center justify-center w-10">
          <FontAwesomeIcon icon={faGooglePlay} size="2x" />
        </div>
        <div className="ml-3">
          <div className="text-xs">{translate("downloadItOn")}</div>
          <div className="text-2xl font-bold">Google Play</div>
        </div>
      </a>
    </div>
  );
}
