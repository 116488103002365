import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { isFAQOpenedVar } from "../../graphql/cache";
import { useIsFaqOpenedQuery } from "../../resolvers-types";
import { FAQ } from "../../types/common";
import Modal from "../modal/Modal";
import FAQSection from "./FAQSection";

export default function FAQModal() {
  const translate = useTranslations();
  const { data } = useIsFaqOpenedQuery();
  const isOpened = data?.isFAQOpened;

  const closeModal = () => {
    isFAQOpenedVar(false);
  };

  const faqs: FAQ[] = [
    {
      question: translate("faq1Question") as string,
      answer: translate("faq1Answer") as string,
      source: translate("sourceDesc") as string,
    },
    {
      question: translate("faq2Question") as string,
      answer: translate("faq2Answer") as string,
      source: translate("sourceDesc") as string,
    },
    {
      question: translate("faq3Question") as string,
      answer: translate("faq3Answer") as string,
    },
    {
      question: translate("faq4Question") as string,
      answer: translate("faq4Answer") as string,
      source: translate("sourceDesc") as string,
    },
    {
      question: translate("faq5Question") as string,
      answer: translate("faq5Answer") as string,
    },
  ];

  return (
    <div className={`${isOpened ? "" : "hidden"}`}>
      <Modal
        title={translate("faq") as string}
        onClickMainAction={closeModal}
        onClose={closeModal}
        isVisible={true}
        isConfirmModal
        shouldHideFooter
        modalClassName="w-1/3"
      >
        <div className="w-full">
          {faqs.map((faq, index) => {
            const { question, answer, source } = faq;

            return (
              <FAQSection
                question={question}
                answer={answer}
                key={`fqa-${index}`}
                source={source}
              />
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
