import "../styles/global.css";
import { NextIntlProvider } from "next-intl"; // NOSONAR
import "react-toastify/dist/ReactToastify.css"; //NOSONAR
import GlobalLoading from "../components/common/GlobalLoading";
import SetupApolloProvider from "../components/common/SetupApolloProvider";
import AdBlockDetect from "../components/adblockDetect/AdBlockDetect";
import MobileEnforcement from "../components/mobileEnforcement/MobileEnforcement";
import mixpanel from "mixpanel-browser";
import React from "react";
import {
  APP_MAIN_COLOR,
  MIXPANEL_TOKEN,
  PROGRESS_HEIGHT,
} from "../constants/common";
import PremiumModal from "../components/premiumModal/PremiumModal";
import NextNProgress from "nextjs-progressbar";
import Script from "next/script";
import FAQModal from "../components/faqModal/FAQModal";
import OnboardingModal from "../components/onboardingModal/OnboardingModal";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../authConfig";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import AppToastContainer from "../components/appToastContainer/AppToastContainer";
import ThemeProvider from "../providers/ThemeProvider";
import SubscriptionHiddenButtons from "../components/subscriptionHiddenButtons/SubscriptionHiddenButtons";
import LoginModal from "../components/loginModal/LoginModal";
config.autoAddCss = false;
const msalInstance = new PublicClientApplication(msalConfig);

export default function App({ Component, pageProps }) {
  React.useEffect(() => {
    mixpanel.init(MIXPANEL_TOKEN);
  }, []);

  return (
    <ThemeProvider>
      <MsalProvider instance={msalInstance}>
        <NextIntlProvider messages={pageProps.messages}>
          <Script
            type="text/javascript"
            src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            strategy="beforeInteractive"
          />
          <SetupApolloProvider pageProps={pageProps}>
            <SubscriptionHiddenButtons />
            <NextNProgress color={APP_MAIN_COLOR} height={PROGRESS_HEIGHT} />
            <Component {...pageProps} />
            <GlobalLoading />
            <PremiumModal />
            <FAQModal />
            <OnboardingModal />
            <LoginModal />
          </SetupApolloProvider>
          <AppToastContainer />
          <AdBlockDetect />
          <MobileEnforcement />
        </NextIntlProvider>
      </MsalProvider>
    </ThemeProvider>
  );
}
