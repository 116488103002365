import React, { useState } from "react";
import { ThemeType } from "../types/common";

interface ThemeProviderProps {
  children: JSX.Element;
}

interface ThemeContextProps {
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
}

export const ThemeContext = React.createContext<ThemeContextProps>({
  theme: ThemeType.Light,
  setTheme: () => {},
});
export default function ThemeProvider({ children }: ThemeProviderProps) {
  const setTheme = (theme: ThemeType) => {
    setState({ ...state, theme });
  };

  const initState: ThemeContextProps = {
    theme: ThemeType.Light,
    setTheme,
  };

  const [state, setState] = useState(initState);

  return (
    <ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
  );
}
