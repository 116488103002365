import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { MONTHLY_PLAN_ID, YEARLY_PLAN_ID } from "../../constants/common";
import { isLoginModalOpenedVar } from "../../graphql/cache";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import Steps from "../steps/Steps";

interface BeforeProceedCheckoutProps {
  selectedPlan: string;
}

export default function BeforeProceedCheckout({
  selectedPlan,
}: BeforeProceedCheckoutProps) {
  const translate = useTranslations();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currentUser, isGuest } = useCurrentUser();

  const openModal = () => {
    if (!isGuest) {
      setIsModalVisible(true);
    } else {
      isLoginModalOpenedVar(true);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const proceedToCheckout = () => {
    if (selectedPlan === YEARLY_PLAN_ID) {
      document.getElementById(YEARLY_PLAN_ID).click();
    } else if (selectedPlan === MONTHLY_PLAN_ID) {
      document.getElementById(MONTHLY_PLAN_ID).click();
    }
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div
        className={`hover:no-underline no-underline bg-main-blue text-white hover:bg-blue-800 font-bold text-center rounded cursor-pointer w-1/2 px-5 text-sm py-3`}
        onClick={openModal}
      >
        {translate("subscribeNow")}
      </div>
      <Modal
        title={translate("beforeCheckout") as string}
        onClickMainAction={closeModal}
        onClose={closeModal}
        isVisible={isModalVisible}
        shouldHideFooter
        modalClassName="w-1/2"
      >
        <div className="flex flex-col items-center justify-center">
          <Steps
            title={
              translate("beforeCheckoutDesc", {
                userDisplayName:
                  currentUser?.displayName || (translate("guest") as string),
              }) as string
            }
            steps={[
              translate("beforeCheckoutStep1") as string,
              translate("beforeCheckoutStep2") as string,
              translate("beforeCheckoutStep3") as string,
              translate("beforeCheckoutStep4") as string,
              translate("beforeCheckoutStep5") as string,
              translate("beforeCheckoutStep6") as string,
            ]}
          />
          <Button
            title={translate("proceedToCheckout")}
            onClick={proceedToCheckout}
            className="mt-5 w-1/2 self-center"
            size="lg"
          />
        </div>
      </Modal>
    </div>
  );
}
