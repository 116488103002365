import React from "react";
import {
  CHARGEBEE_MANAGE_ACCOUNT_BTN_ID,
  MONTHLY_PLAN_ID,
  YEARLY_PLAN_ID,
} from "../../constants/common";

export default function SubscriptionHiddenButtons() {
  return (
    <div>
      <a
        data-cb-type="checkout"
        data-cb-item-0={MONTHLY_PLAN_ID}
        data-cb-item-0-quantity="1"
        className="hidden"
        id={MONTHLY_PLAN_ID}
      />
      <a
        data-cb-type="checkout"
        data-cb-item-0={YEARLY_PLAN_ID}
        data-cb-item-0-quantity="1"
        className="hidden"
        id={YEARLY_PLAN_ID}
      />
      <a
        data-cb-type="portal"
        id={CHARGEBEE_MANAGE_ACCOUNT_BTN_ID}
        className="hidden"
      />
    </div>
  );
}
