import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import ReactMarkdown from "react-markdown";
import { FAQ } from "../../types/common";
import remarkBreak from "remark-breaks";

interface FAQSectionProps extends FAQ {
  source?: string;
}

export default function FAQSection({
  question,
  answer,
  source,
}: FAQSectionProps) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpansion = () => {
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  };

  return (
    <div className="w-full mb-10">
      <div
        className="cursor-pointer text-base font-semibold flex flex-row items-center"
        onClick={toggleExpansion}
      >
        <FontAwesomeIcon
          icon={isExpanded ? faChevronCircleUp : faChevronCircleDown}
          className={`text-main-blue cursor-pointer mr-3`}
          size="lg"
        />{" "}
        {question}
      </div>
      <div className={`mt-6 text-sm leading-8 ${isExpanded ? "" : "hidden"}`}>
        <ReactMarkdown remarkPlugins={[remarkBreak]}>{answer}</ReactMarkdown>
        {source && (
          <div className="text-xs mt-5">
            <ReactMarkdown>{source}</ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  );
}
