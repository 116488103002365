import React from "react";
import * as animationData from "../../animations/loading-2.json";
import Lottie from "react-lottie";
import { useTranslations } from "next-intl";

interface LoadingIconProps {
  size?: number;
  textColorClass?: string;
}

const DEFAULT_SIZE = 150;

export default function LoadingIcon({
  size = DEFAULT_SIZE,
  textColorClass,
}: LoadingIconProps) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const translate = useTranslations();

  return (
    <div className="flex items-center justify-center flex-col  relative">
      <Lottie options={defaultOptions} width={size} />
      <div
        className={`text-xs bottom-1 absolute font-semibold ${textColorClass}`}
      >
        {translate("loading")}
      </div>
    </div>
  );
}
