import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useTranslations } from "next-intl";
import React from "react";
import {
  DEFAULT_ONBOARDING_MODAL,
  isOnboardingModalOpenedVar,
} from "../../graphql/cache";
import { Onboarding } from "../../types/common";
import Modal from "../modal/Modal";
import Image from "next/image";
import { useIsOnboardingModalOpenedQuery } from "../../resolvers-types";
import OB1 from "../../public/images/onboarding-desktop-1.png";
import OB2 from "../../public/images/onboarding-desktop-2.png";
import OB3 from "../../public/images/onboarding-desktop-3.png";
import OB4 from "../../public/images/onboarding-desktop-4.png";
import OB5 from "../../public/images/onboarding-desktop-5.png";
import OB6 from "../../public/images/onboarding-desktop-6.png";

const IMAGE_HEIGHT = 558;
const IMAGE_WIDTH = 560;

export default function OnboardingModal() {
  const translate = useTranslations();
  const { data } = useIsOnboardingModalOpenedQuery();
  const isOpened = data?.isOnboardingModalOpened?.isOpened;
  const modalTitle = data?.isOnboardingModalOpened?.title;

  const closeModal = () => {
    isOnboardingModalOpenedVar(DEFAULT_ONBOARDING_MODAL);
  };

  const onboardings: Onboarding[] = [
    {
      id: "ob-1",
      imgSouce: OB1,
      title: translate("ob1Title") as string,
      desc: translate("ob1Desc") as string,
    },
    {
      id: "ob-2",
      imgSouce: OB2,
      title: translate("ob2Title") as string,
      desc: translate("ob2Desc") as string,
    },
    {
      id: "ob-3",
      imgSouce: OB3,
      title: translate("ob3Title") as string,
      desc: translate("ob3Desc") as string,
    },
    {
      id: "ob-4",
      imgSouce: OB4,
      title: translate("ob4Title") as string,
      desc: translate("ob4Desc") as string,
    },
    {
      id: "ob-5",
      imgSouce: OB5,
      title: translate("ob5Title") as string,
      desc: translate("ob5Desc") as string,
    },
    {
      id: "ob-6",
      imgSouce: OB6,
      title: translate("ob6Title") as string,
      desc: translate("ob6Desc") as string,
    },
  ];

  return (
    <>
      <Modal
        title={(modalTitle || translate("onboarding")) as string}
        onClickMainAction={closeModal}
        onClose={closeModal}
        isVisible={isOpened}
        isConfirmModal
        shouldHideFooter
        modalClassName="w-560"
        bodyClassName="px-0"
        shouldIgnoreTheme
      >
        <div className="w-full">
          <Splide
            options={{
              type: "loop",
              gap: 0,
              height: IMAGE_HEIGHT,
            }}
          >
            {onboardings.map((onboarding, index) => {
              const { imgSouce, id } = onboarding;
              const isLastItem = index === onboardings.length - 1;
              const placeholder = isLastItem ? "empty" : "blur";
              return (
                <SplideSlide key={`benefit-${id}`}>
                  <div className="flex flex-col relative items-center justify-center">
                    <Image
                      src={imgSouce}
                      alt={onboarding.title}
                      width={IMAGE_WIDTH}
                      height={IMAGE_HEIGHT}
                      priority
                      placeholder={placeholder}
                    />
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </Modal>
      {onboardings.map((onboarding) => {
        const { id, title, desc } = onboarding;
        return (
          <div key={`benefit-${id}`} className="hidden">
            <div className="text-lg">{title}</div>
            <div className="text-base">{desc}</div>
          </div>
        );
      })}
    </>
  );
}
