import React from "react";
import ReactMarkdown from "react-markdown";

interface StepsProps {
  title: string;
  steps: string[];
}

export default function Steps({ title, steps }: StepsProps) {
  return (
    <div className="w-full">
      <div className="font-medium mb-4">
        <ReactMarkdown>{title}</ReactMarkdown>
      </div>
      {steps.map((step, index) => (
        <div
          key={`${step}-${index}`}
          className="py-2 flex flex-row items-center"
        >
          <b className="mr-2">{index + 1}.</b>
          {"   "}
          <ReactMarkdown>{step}</ReactMarkdown>
        </div>
      ))}
    </div>
  );
}
