import { InMemoryCache, makeVar } from "@apollo/client";
import { OnboardingModal, User } from "../resolvers-types";

export const isGlobalLoadingVar = makeVar(false);
export const isCreateEstimateRoomModalVisibleVar = makeVar(false);
export const isProModalOpenedVar = makeVar(false);
export const isFAQOpenedVar = makeVar(false);
export const DEFAULT_ONBOARDING_MODAL: OnboardingModal = {
  isOpened: false,
  title: "",
};
export const isOnboardingModalOpenedVar = makeVar<OnboardingModal>(
  DEFAULT_ONBOARDING_MODAL
);
export const isLoginModalOpenedVar = makeVar(false);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isGlobalLoading: {
          read() {
            return isGlobalLoadingVar();
          },
        },
        isCreateEstimateRoomModalVisible: {
          read() {
            return isCreateEstimateRoomModalVisibleVar();
          },
        },
        isProModalOpened: {
          read() {
            return isProModalOpenedVar();
          },
        },
        isFAQOpened: {
          read() {
            return isFAQOpenedVar();
          },
        },
        isOnboardingModalOpened: {
          read() {
            return isOnboardingModalOpenedVar();
          },
        },
        isLoginModalOpened: {
          read() {
            return isLoginModalOpenedVar();
          },
        },
      },
    },
  },
});
