import React from "react";
import { useDetectAdBlock } from "adblock-detect-react";
import { useTranslations } from "next-intl";
import * as animationData from "../../animations/shiba-sad.json";
import Lottie from "react-lottie";
import Button from "../button/Button";

const ANIM_SIZE = 250;

export default function AdBlockDetect() {
  const adBlockDetected = useDetectAdBlock();
  const translate = useTranslations();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const handleClickReload = () => {
    window.location.reload();
  };

  if (adBlockDetected) {
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 flex flex-col items-center justify-center z-50">
        <section
          className={`overflow-auto fixed bg-white dark:bg-dark-blue w-1/3 max-h-90% max-w-90% rounded-md flex flex-col`}
        >
          <div className="pt-5 pb-10 px-10">
            <Lottie options={defaultOptions} width={ANIM_SIZE} />
            <div className="text-2xl font-bold">
              {translate("adBlockerTitle")}
            </div>
            <div className="mt-3">{translate("adBlockerMessage")}</div>
            <div className="mt-3 font-semibold">
              {translate("adBlockerMessage2")}
            </div>
            <Button
              title={translate("adBlockerBtnTitle")}
              onClick={handleClickReload}
              className="mt-10"
              size="lg"
            />
          </div>
        </section>
      </div>
    );
  }

  return null;
}
