import { SubPlatform } from "../resolvers-types";

export interface OpionType {
  label: string;
  value: string;
}

export enum ThemeType {
  Light = "Light",
  Dark = "Dark",
}

export interface RestoreInstruction {
  optionLabel: string;
  subPlatform: SubPlatform;
  steps: string[];
}

export interface FAQ {
  question: string;
  answer: string;
  source?: string;
}

export interface Onboarding {
  title: string;
  desc: string;
  id: string;
  imgSouce: StaticImageData;
}

export interface Article {
  id: string;
  title: string;
  date: string;
  author: string;
  authorAvatar: string;
  tags: string[];
  thumbnail: string;
  sourceURL: string;
  sourceName: string;
}

export interface ArticleData extends Article {
  contentHtml: any;
}
