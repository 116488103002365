import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import React from "react";
import { useApollo } from "../../lib/apollo";

interface SetupApolloProviderProps {
  children: JSX.Element | JSX.Element[];
  pageProps: any;
}

export default function SetupApolloProvider({
  children,
  pageProps,
}: SetupApolloProviderProps) {
  const apolloClient: ApolloClient<NormalizedCacheObject> =
    useApollo(pageProps);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
