import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNodeArray,
} from "react";

interface ButtonProps {
  title:
    | string
    | ReactNodeArray
    | ReactElement<any, string | JSXElementConstructor<any>>;
  onClick: () => void;
  className?: string;
  isSecondaryBtn?: boolean;
  size?: "sm" | "md" | "lg";
  icon?: IconDefinition;
}

export default function Button({
  title,
  onClick,
  className,
  isSecondaryBtn,
  size = "sm",
  icon,
}: ButtonProps) {
  const buttonClass = `${
    isSecondaryBtn
      ? "bg-gray-200 text-gray-500 hover:bg-gray-300"
      : "bg-main-blue text-white hover:bg-blue-800"
  }`;

  const getBtnStyleBySize = () => {
    switch (size) {
      case "sm":
        return "text-xs py-2";
      default:
        return "text-sm py-3";
    }
  };

  const btnStyle = getBtnStyleBySize();

  return (
    <div
      className={`${buttonClass} font-bold text-center rounded cursor-pointer w-full flex flex-row items-center justify-center ${btnStyle} ${className}`}
      onClick={onClick}
    >
      {icon && (
        <FontAwesomeIcon icon={icon} className="mr-3 text-white" size="lg" />
      )}
      {title}
    </div>
  );
}
