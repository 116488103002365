import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../button/Button";
import { useTranslations } from "next-intl";
interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  title: string;
  onClickMainAction?: () => void;
  mainActionTitle?: string;
  cancelActionTitle?: string;
  isVisible: boolean;
  isConfirmModal?: boolean;
  shouldHideFooter?: boolean;
  modalClassName?: string;
  bodyClassName?: string;
  shouldIgnoreTheme?: boolean;
}

export default function Modal({
  children,
  onClickMainAction,
  title,
  mainActionTitle,
  cancelActionTitle,
  onClose,
  isVisible,
  isConfirmModal,
  shouldHideFooter,
  modalClassName,
  bodyClassName,
  shouldIgnoreTheme,
}: ModalProps) {
  const translate = useTranslations();

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 flex flex-col items-center justify-center z-50">
      <section
        className={`relative bg-white ${
          !shouldIgnoreTheme && "dark:bg-dark-blue"
        } min-w-350 max-h-90% max-w-90% rounded-md flex flex-col ${modalClassName}`}
      >
        <div
          className={`flex flex-row justify-between rounded-t-md py-4 border-b-2 ${
            !shouldIgnoreTheme && "dark:border-gray-800 dark:bg-dark-blue"
          } border-gray-200 px-5 absolute bg-white w-full z-50 top-0`}
        >
          <div
            className={`text-sm font-semibold pr-4 max-w-sm text-dark-blue ${
              !shouldIgnoreTheme && "dark:text-white"
            }`}
          >
            {title}
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className={`text-gray-900 scale-110 ${
              !shouldIgnoreTheme && "dark:text-white"
            } cursor-pointer`}
            onClick={onClose}
          />
        </div>
        <div
          className={`px-5  ${
            shouldHideFooter ? "pb-8" : "pb-20"
          } pt-20 overflow-auto ${bodyClassName}`}
        >
          {children}
        </div>
        {!shouldHideFooter && (
          <div
            className={`py-3 flex flex-row justify-end px-5 border-t-2 rounded-b-md ${
              !shouldIgnoreTheme && "dark:border-gray-800 dark:bg-dark-blue"
            } border-gray-200 absolute bottom-0 w-full bg-white z-50`}
          >
            <div className="flex flex-row items-center ">
              <Button
                title={cancelActionTitle || translate("cancel")}
                onClick={onClose}
                className="!w-20 mr-2"
                isSecondaryBtn
              />
              <Button
                title={
                  mainActionTitle ||
                  (isConfirmModal ? translate("yes") : translate("ok"))
                }
                onClick={onClickMainAction}
                className="!w-20"
              />
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
