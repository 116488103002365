import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { APP_INFO } from "../../constants/common";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { SubPlatform } from "../../resolvers-types";
import { RestoreInstruction } from "../../types/common";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import Steps from "../steps/Steps";

const DEFAULT_SELECTED_INSTRUCTION_INDEX = -1;

export default function RestorePurchase() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currentUser } = useCurrentUser();
  const translate = useTranslations();
  const currentUserName = currentUser?.displayName || translate("guest");
  const [selectedInstructionIndex, setSelectedInstructionIndex] =
    useState<number>(DEFAULT_SELECTED_INSTRUCTION_INDEX);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    resetInstruction();
  };

  const resetInstruction = () => {
    setSelectedInstructionIndex(DEFAULT_SELECTED_INSTRUCTION_INDEX);
  };

  const restoreInstructions: RestoreInstruction[] = [
    {
      optionLabel: translate("webApp") as string,
      subPlatform: SubPlatform.Chargebee,
      steps: [
        `${translate("restoreWebStep1")} [${APP_INFO.supportEmail}](mailto:${
          APP_INFO.supportEmail
        }).` as string,
        translate("restoreWebStep2") as string,
      ],
    },
    {
      optionLabel: translate("androidApp") as string,
      subPlatform: SubPlatform.Google,
      steps: [
        translate("restoreAndroidStep1") as string,
        translate("restoreAndroidStep2") as string,
        translate("restoreAndroidStep3") as string,
        translate("restoreAndroidStep4") as string,
        translate("restoreAndroidStep5") as string,
      ],
    },
    {
      optionLabel: translate("iosApp") as string,
      subPlatform: SubPlatform.Apple,
      steps: [
        translate("restoreAppleStep1") as string,
        translate("restoreAndroidStep2") as string,
        translate("restoreAndroidStep3") as string,
        translate("restoreAndroidStep4") as string,
        translate("restoreAndroidStep5") as string,
      ],
    },
  ];

  const handleSelectInstruction = (index: number) => () => {
    setSelectedInstructionIndex(index);
  };

  const instruction = restoreInstructions[selectedInstructionIndex];

  return (
    <div>
      <div
        className="text-sm mt-3 cursor-pointer text-main-blue"
        onClick={openModal}
      >
        {translate("restorePurchase")}
      </div>
      <Modal
        title={translate("restorePurchase") as string}
        onClickMainAction={closeModal}
        onClose={closeModal}
        isVisible={isModalVisible}
        isConfirmModal
        shouldHideFooter
      >
        <div className="w-full">
          <div className="w-full text-sm flex flex-col items-center justify-center">
            {instruction ? (
              <div className="w-full flex flex-col">
                <Steps
                  title={translate("restoreTitle") as string}
                  steps={instruction.steps}
                />
                <Button
                  title={translate("goBack")}
                  onClick={resetInstruction}
                  className="mt-5 w-1/2 self-center"
                  size="lg"
                />
              </div>
            ) : (
              <div>
                <div>
                  {translate("hi")}{" "}
                  <b className="text-main-blue">{currentUserName}</b>,{" "}
                  {translate("restoreQuestion")}{" "}
                  <b>{translate("agileProUser")}</b>?
                </div>
                <div className="mt-6">
                  <div className="flex flex-col w-full items-center justify-center">
                    {restoreInstructions.map((restoreInstruction, idx) => {
                      return (
                        <div
                          className="w-full py-5 bg-main-blue my-3 rounded-md cursor-pointer hover:bg-blue-800"
                          onClick={handleSelectInstruction(idx)}
                          key={`restore-instruction-${idx}`}
                        >
                          <div className="text-center text-white font-black">
                            {restoreInstruction.optionLabel}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
