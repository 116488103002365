import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_POST_LOGIN_PAGE,
  TRACKING_EVENT,
} from "../constants/common";
import mixpanel from "mixpanel-browser";
import { SubPlatform, User } from "../resolvers-types";

export const redirectAfterLogin = () => {
  const postUrlAfterLogin = sessionStorage.getItem(
    LOCAL_STORAGE_POST_LOGIN_PAGE
  );
  if (postUrlAfterLogin) {
    window.location.replace(postUrlAfterLogin);
    sessionStorage.removeItem(LOCAL_STORAGE_POST_LOGIN_PAGE);
  } else {
    window.location.replace("/");
  }
};

export const handleSignOut = async () => {
  mixpanel.track(TRACKING_EVENT.logout);
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
  window.location.replace("/");
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const identifyMixpanelProfile = (currentUser: User) => {
  if (currentUser) {
    mixpanel.identify(currentUser.id.toString());
    mixpanel.people.set("email", currentUser.email);
    mixpanel.people.set("displayName", currentUser.displayName);
    mixpanel.people.set("avatarUrl", currentUser.avatarUrl);
  }
};

export const getSubPlatform = (subPlatform: SubPlatform, translate: any) => {
  if (subPlatform === SubPlatform.Chargebee) {
    return translate("web");
  }
  return subPlatform;
};

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
};
