import { OpionType } from "../types/common";

export const APP_INFO = {
  appName: "Agile Company",
  supportEmail: "apps.nathan.truong@gmail.com",
  androidPackageName: "com.nathan.agilecompany",
  iOSBundleId: "com.nathan.agilecompany",
  publisherId: "pub-3318530912901059",
  iosAppId: "1589587892",
  appleLoginClientId: "com.nathan.agilecompany.web",
};
export const APP_URL_CONFIG = {
  deeplinkFallbackURLAndroid:
    "https://play.google.com/store/apps/details?id=com.nathan.agilecompany",
  deeplinkFallbackURLIOS:
    "https://apps.apple.com/us/app/agile-company/id1589587892",
  privacyPolicy: "https://agile-company-0.flycricket.io/privacy.html",
  geolocationAPI: "https://geolocation-db.com/json/",
  dynamicCreateShortLinkURL: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.NEXT_PUBLIC_DYNAMIC_LINK_KEY}`,
  defaultAvatarURL:
    "https://ui-avatars.com/api?size=1080&bold=true&background=4054E8&color=FFFFFF&name=",
};
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const LOCAL_STORAGE_ACCESS_TOKEN = "user_access_token";
export const STORY_POINTS: OpionType[] = [
  {
    label: "?",
    value: "?",
  },
  {
    label: "0",
    value: "0",
  },
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "13",
    value: "13",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "40",
    value: "40",
  },
  {
    label: "89",
    value: "89",
  },
  {
    label: "SPLIT",
    value: "SPLIT",
  },
];

export const INACTIVITY_DEBOUNCE = 500;
export const INACTIVITY_TIMEOUT = 0.5 * 60 * 1000;
export const LOCAL_STORAGE_POST_LOGIN_PAGE = "post_login_page";
export const LOCAL_STORAGE_THEME_KEY = "theme";
export const LOCAL_STORAGE_ONBOARDING_KEY = "onboarding";
export const MAX_AMOUNT_SHOWED_PLAYERS = 5;
export const GOOGLE_ADSENSE_CLIENT_ID = "ca-pub-3318530912901059";
export const ROUND_DIGIT = 1;
export const MIXPANEL_TOKEN = "8bdf3ad217cf268258eee19d15aa8d50";
export const TRACKING_EVENT = {
  addStoryPoint: "Add Story Point",
  denyAppTracking: "Deny App Tracking",
  grantAppTracking: "Grant App Tracking",
  blockAppTracking: "Block App Tracking",
  bannerAdFailedToLoad: "Banner Ad failed to load",
  interstitialAdFailedToLoad: "Interstitial Ad failed to load",
  interstitialAdShowed: "Interstitial Ad showed",
  upgradeToPro: "Upgrade To Pro User",
  restoreSuccess: "Restore Pro User Success",
  showAppPreview: "Show App Preview",
  rateTheApp: "Rate The App",
  createRoom: "Create Room",
  login: "Login",
  logout: "Logout",
  joinedViaDeeplink: "Joined Via Deeplink",
  copyDeeplink: "Copy Deeplink",
  selectTheme: "Select Theme",
  updateDisplayName: "Update Display Name",
  toggleHapticFeedback: "Toggle Haptic Feedback",
  editRoomInfo: "Edit Room Info",
};
export const DEFAULT_DISPLAY_NAME = "Agile User";
export const DATE_FORMAT = "dddd, MMMM D, YYYY";
export const MONTHLY_PLAN_ID =
  process.env.NEXT_PUBLIC_CHARGEBEE_MONTHLY_PLAN_ID;
export const YEARLY_PLAN_ID = process.env.NEXT_PUBLIC_CHARGEBEE_YEARLY_PLAN_ID;
export const MONTHLY_PLAN_PRICE = "2.49$";
export const YEARLY_PLAN_PRICE = "22.49$";
export const CHARGEBEE_MANAGE_ACCOUNT_BTN_ID = "manage-account-btn";
export const MAX_LENGTH_OF_STORY_POINT = 3;
export const APP_MAIN_COLOR = "#4054E8";
export const MAX_FILE_SIZE = 10485760;
export const MAX_ADDITIONAL_TOTAL_PLAYERS = 99;
export const PROGRESS_HEIGHT = 4;
export const CLOSE_POPUP_GOOGLE_MSG = "auth/popup-closed-by-user";
export const CLOSE_POPUP_MICROSOFT_MSG =
  "user_cancelled: User cancelled the flow";
export const SUB_STATUS_BASE_STYLE =
  "font-bold text-white px-3 text-sm py-1 w-fit-content rounded-lg mt-2";
export const ARTICLE_ITEM_WIDTH = 400;
