import { gql } from "apollo-server-micro"; // NOSONAR

export const clientTypeDefs = gql`
  extend type Query {
    isGlobalLoading: Boolean!
    isCreateEstimateRoomModalVisible: Boolean!
    isProModalOpened: Boolean!
    isFAQOpened: Boolean!
    isOnboardingModalOpened: OnboardingModal!
    isLoginModalOpened: Boolean!
  }

  extend type OnboardingModal {
    isOpened: Boolean!
    title: String!
  }
`;
