import { useTranslations } from "next-intl";
import React from "react";
import { useIsProModalOpenedQuery } from "../../resolvers-types";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { isProModalOpenedVar } from "../../graphql/cache";
import "@splidejs/splide/dist/css/splide.min.css";
import PremiumBenefits from "./PremiumBenefits";
import Plans from "./Plans";
import RestorePurchase from "./RestorePurchase";
import AppLogo from "../../public/images/app-logo.png";

export default function PremiumModal() {
  const { data } = useIsProModalOpenedQuery();
  const isOpened = data?.isProModalOpened;
  const translate = useTranslations();

  const handleCloseModal = () => {
    isProModalOpenedVar(false);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 flex flex-col items-center justify-center z-50 ${
        !isOpened ? "hidden" : ""
      }`}
    >
      <section
        className={`overflow-auto fixed bg-white dark:bg-dark-blue w-2/3 xl:w-1/3 max-h-90% max-w-90% rounded-md flex flex-col`}
      >
        <div className="flex flex-col items-center justify-center pt-6 pb-10 px-3">
          <FontAwesomeIcon
            icon={faTimes}
            className="text-gray-900 cursor-pointer absolute right-6 top-5"
            onClick={handleCloseModal}
            size="lg"
          />
          <div className="text-lg font-bold mt-6">
            {translate("subscription")}
          </div>
          <div className="text-2xl text-main-blue font-black mt-1">
            {translate("agileProUser")}
          </div>
          <div className="mt-3 border-4 border-yellow-400 rounded-full flex items-center justify-center">
            <Image
              src={AppLogo}
              alt="logo"
              width="90"
              height="90"
              className="rounded-full"
              priority
              placeholder="blur"
            />
          </div>
          {isOpened && <PremiumBenefits />}
          <Plans />
          <RestorePurchase />
        </div>
      </section>
    </div>
  );
}
