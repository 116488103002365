import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { LoginWithGoogleInput } from "../resolvers-types";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_API_KEY,
  authDomain: "agile-company.firebaseapp.com",
  projectId: "agile-company",
  storageBucket: "agile-company.appspot.com",
  messagingSenderId: "138549594380",
  appId: "1:138549594380:web:00b1ba77cbff360f3c2129",
  measurementId: "G-MZRDSB1RZF",
};

export const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
const auth = getAuth();

export const signInWithGoogle = async (): Promise<LoginWithGoogleInput> => {
  try {
    const result = await signInWithPopup(auth, provider);
    const { displayName, email, photoURL: avatarUrl } = result.user;
    return {
      displayName,
      email,
      avatarUrl,
    };
  } catch (error) {
    const errorMessage = error.message;
    throw new Error(
      `Error in signing with Google Account. Error Message: ${errorMessage}`
    );
  }
};
