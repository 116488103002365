import {
  CurrentUserDocument,
  LoginWithGoogleInput,
  useLoginWithGoogleMutation,
} from "../resolvers-types";
import { storeAccessToken } from "../utils/common";

export const useLogGuestUserIn = (): ((
  loginUserInput: LoginWithGoogleInput
) => Promise<void>) => {
  const [loginWithGoogleAPI] = useLoginWithGoogleMutation();

  const logFunc = async (loginUserInput: LoginWithGoogleInput) => {
    await loginWithGoogleAPI({
      variables: { input: loginUserInput },
      update: (cache, { data: dataLogin }) => {
        const currentUser = dataLogin?.loginWithGoogle;
        const userToken = currentUser?.accessToken;
        if (userToken) {
          storeAccessToken(userToken);
        }
        cache.writeQuery({
          query: CurrentUserDocument,
          data: {
            currentUser: {
              ...dataLogin?.loginWithGoogle,
            },
          },
        });
      },
    });
  };
  return logFunc;
};
