import { useTranslations } from "next-intl";
import React, { useEffect } from "react";
import LoginButton from "../loginButton/LoginButton";
import mixpanel from "mixpanel-browser";
import {
  APP_INFO,
  CLOSE_POPUP_GOOGLE_MSG,
  CLOSE_POPUP_MICROSOFT_MSG,
  DEFAULT_DISPLAY_NAME,
  TRACKING_EVENT,
} from "../../constants/common";
import { isGlobalLoadingVar } from "../../graphql/cache";
import { signInWithGoogle } from "../../lib/firebase";
import { toast } from "react-toastify";
import { LoginWithGoogleInput } from "../../resolvers-types";
import { graphConfig, loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import jwt_decode from "jwt-decode";

interface LoginOptionsProps {
  handleLogUserIn: (loginUserInput: LoginWithGoogleInput) => Promise<void>;
}

export default function LoginOptions({ handleLogUserIn }: LoginOptionsProps) {
  const translate = useTranslations();
  const { instance } = useMsal();

  useEffect(() => {
    const windowAny: any = window;
    windowAny.AppleID.auth.init({
      clientId: APP_INFO.appleLoginClientId,
      scope: "name email",
      redirectURI: process.env.NEXT_PUBLIC_APPLE_LOGIN_REDIRECT_URI,
      state: "origin:web",
      usePopup: true,
    });
  });

  const handleLoginWithGoogle = async () => {
    mixpanel.track(TRACKING_EVENT.login);
    try {
      isGlobalLoadingVar(true);
      const loginUserInput = await signInWithGoogle();
      await handleLogUserIn(loginUserInput);
    } catch (error) {
      isGlobalLoadingVar(false);
      if (
        error &&
        error.message &&
        error.message.includes(CLOSE_POPUP_GOOGLE_MSG)
      ) {
        return;
      }
      toast.error(error.message);
    }
  };

  const handleLoginWithMicrosoft = async () => {
    try {
      isGlobalLoadingVar(true);
      const { accessToken } = await instance.loginPopup(loginRequest);
      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers,
      };
      const response = await fetch(graphConfig.graphMeEndpoint, options);
      const accountData = await response.json();
      const loginUserInput: LoginWithGoogleInput = {
        displayName: accountData.displayName,
        email: accountData.mail || accountData.userPrincipalName,
        additionalLoginInfo: {
          accessToken,
          userId: accountData.id,
        },
      };
      await handleLogUserIn(loginUserInput);
    } catch (error) {
      isGlobalLoadingVar(false);
      if (
        error &&
        error.message &&
        error.message.includes(CLOSE_POPUP_MICROSOFT_MSG)
      ) {
        return;
      }
      toast.error(error.message);
    }
  };

  const handleLoginWithApple = async () => {
    try {
      isGlobalLoadingVar(true);
      const windowAny: any = window;
      const appleAuthResp = await windowAny.AppleID.auth.signIn();
      const { authorization: appleAuthRequestResponse, user } = appleAuthResp;
      const { email } = jwt_decode(appleAuthRequestResponse.id_token) as any;
      let userDisplayName = DEFAULT_DISPLAY_NAME;
      if (user && user.name) {
        userDisplayName = `${user.name.firstName} ${user.name.lastName}`;
      }
      const loginUserInput: LoginWithGoogleInput = {
        displayName: userDisplayName,
        email: email ?? "",
      };
      await handleLogUserIn(loginUserInput);
    } catch (err) {
      isGlobalLoadingVar(false);
      if (err.error) {
        if (err.error === "popup_closed_by_user") {
          return;
        }
        toast.error(err.error);
        return;
      }
      if (err.message) {
        toast.error(err.message);
        return;
      }
      toast.error(JSON.stringify(err));
    }
  };

  return (
    <div className="flex flex-col">
      <LoginButton
        title={translate("loginWithGoogle") as string}
        onClick={handleLoginWithGoogle}
        svgIconUrl="/images/google.svg"
      />
      <LoginButton
        title={translate("loginWithMicrosoft") as string}
        onClick={handleLoginWithMicrosoft}
        svgIconUrl="/images/microsoft.svg"
      />
      <LoginButton
        title={translate("loginWithApple") as string}
        onClick={handleLoginWithApple}
        svgIconUrl="/images/apple.svg"
        logoClassName="mb-1 scale-110"
      />
    </div>
  );
}
