import { useTranslations } from "next-intl";
import React from "react";
import Image from "next/image";
import agMobile from "../../public/images/ag-mobile.png";
import DownloadSection from "../downloads/DownloadSection";

export default function MobileEnforcement() {
  const translate = useTranslations();

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 flex flex-col items-center justify-center z-50 md:hidden">
      <section
        className={`overflow-auto fixed bg-white dark:bg-dark-blue w-full h-full flex flex-col items-center justify-center`}
      >
        <div className="px-10 py-5 text-center">
          <div className="text-base font-semibold">
            {translate("mobileEnforcement")}
          </div>
          <div className="mt-10 flex flex-col justify-center items-center">
            <div className="w-full flex flex-row items-center justify-center mb-4">
              <div className="w-1/2">
                <Image
                  src={agMobile}
                  alt="ag-desktop"
                  className="rounded-lg"
                  width={865}
                  height={1652}
                  placeholder="blur"
                />
              </div>
            </div>
            <DownloadSection />
          </div>
        </div>
      </section>
    </div>
  );
}
